// All the action menues used in the admin section for player management in an event is listed here.

// Action menu that is used in the confirmed players list when the signup method is GROUPS.

export function GroupSignupConfirmedPlayersActionMenu(event, player, wildcardsLeft, isEventReallyStarted) {
  const items = [];
  const subItems = [];

  if (event.status != "COMPLETED") {
    if (player.onSite == false) {
      items.push({
        title: "Mark as checked In",
        action: "checkIn",
        disabled: false,
        textColor: "",
        subItems: [],
        confirmationRequired: false,
        separator: null,
      });
    } else {
      items.push({
        title: "Unmark as checked in",
        action: "checkIn",
        disabled: false,
        textColor: "",
        subItems: [],
        confirmationRequired: false,
        separator: null,
      });
    }
  }

  if (event.tour.paymentType == "MANUAL") {
    if (player.hasPaid) {
      items.push({
        title: "Unmark as paid",
        action: "removepayment",
        disabled: false,
        textColor: "",
        subItems: [],
        confirmationRequired: false,
        separator: null,
      });
    } else {
      items.push({
        title: "Mark as paid",
        action: "addpayment",
        disabled: false,
        textColor: "",
        subItems: [],
        confirmationRequired: false,
        separator: null,
      });
    }
  }
  if (player.isWildcard) {
    items.push({
      title: "Remove wildcard",
      action: "removewildcard",
      disabled: false,
      textColor: "",
      subItems: [],
      confirmationRequired: false,
      separator: null,
    });
  } else if (wildcardsLeft > 0) {
    items.push({
      title: "Make wildcard",
      action: "addwildcard",
      disabled: false,
      textColor: "",
      subItems: [],
      confirmationRequired: false,
      separator: null,
    });
  }
  if (event.tour.divisions.length > 1) {
    event.tour.divisions.forEach((division) => {
      subItems.push({
        title: division.type,
        value: {
          divisionId: division.id,
          playerId: player.id,
        },
        action: "changeDivsion",
        disabled: false,
        textColor: "",
      });
    });

    items.push({
      title: "Change division",
      action: "",
      disabled: false,
      textColor: "",
      subItems: subItems,
      confirmationRequired: false,
      separator: "bottom",
    });
  }
  if (isEventReallyStarted == false) {
    items.push({
      title: "Send to waiting list",
      action: "waitinglist",
      disabled: false,
      textColor: "",
      subItems: [],
      confirmationRequired: false,
      separator: null,
    });

    if (event.tour.paymentType == "MANUAL") {
      items.push({
        title: "Remove registration",
        action: "removeplayer",
        disabled: false,
        textColor: "#FF0064",
        subItems: [],
        confirmationRequired: false,
        separator: null,
      });
    } else {
      items.push({
        title: "Remove & refund",
        action: "refund",
        disabled: false,
        textColor: "",
        subItems: [],
        confirmationRequired: false,
        separator: null,
      });
    }
  }

  return items;
}
// Action menu that is used in the waitinglist when the signup method is GROUPS.
// Several actions are removed from this compared to the LIST signup option.

export function GroupSignupWaitingListActionMenu(event, player, isEventReallyStarted) {
  const items = [];
  const subItems = [];

  if (event.status != "COMPLETED") {
    if (isEventReallyStarted) {
      items.push({
        title: "Confirm player",
        action: "accept",
        disabled: false,
        textColor: "",
        subItems: [],
        confirmationRequired: false,
        separator: null,
      });
    }

    if (event.tour.paymentType == "MANUAL") {
      if (player.hasPaid) {
        items.push({
          title: "Unmark as paid",
          action: "removepayment",
          disabled: false,
          textColor: "",
          subItems: [],
          confirmationRequired: false,
          separator: null,
        });
      } else {
        items.push({
          title: "Mark as paid",
          action: "addpayment",
          disabled: false,
          textColor: "",
          subItems: [],
          confirmationRequired: false,
          separator: null,
        });
      }
    }
  }
  if (event.tour.divisions.length > 1) {
    event.tour.divisions.forEach((division) => {
      subItems.push({
        title: division.type,
        value: {
          divisionId: division.id,
          playerId: player.id,
        },
        action: "changeDivsion",
        disabled: false,
        textColor: "",
      });
    });

    items.push({
      title: "Change division",
      action: "",
      disabled: false,
      textColor: "",
      subItems: subItems,
      confirmationRequired: false,
      separator: "bottom",
    });
  }

  if (isEventReallyStarted == false) {
    if (event.tour.paymentType == "MANUAL") {
      items.push({
        title: "Remove registration",
        action: "remove",
        disabled: false,
        textColor: "#FF0064",
        subItems: [],
        confirmationRequired: false,
        separator: null,
      });
    } else {
      items.push({
        title: player.hasPaid ? "Remove & refund" : "Remove registration",
        action: "remove",
        disabled: false,
        textColor: "#FF0064",
        subItems: [],
        confirmationRequired: false,
        separator: null,
      });
    }
  }

  return items;
}

// Action menu that is used in the canceled registration, currently the same options apply to all registration methods so the same one is used.

export function CancelledRegistrationsActionMenu(event, player, isEventReallyStarted) {
  const items = [];

  if (event.tour.paymentType == "MANUAL") {
    if (player.hasPaid) {
      items.push({
        title: "Unmark as paid",
        action: "removepayment",
        disabled: false,
        textColor: "",
        subItems: [],
      });
    } else {
      items.push({
        title: "Mark as paid",
        action: "addpayment",
        disabled: false,
        textColor: "",
        subItems: [],
      });
    }
  }
  if (isEventReallyStarted == false) {
    items.push({
      title: "Send to waiting list",
      action: "waitinglist",
      disabled: false,
      textColor: "",
      subItems: [],
    });
  }
  return items;
}

// Action menu that is used in the confirmed players list when the signup method is LIST.

export function ListSignupConfirmedPlayersActionMenu(event, player, wildcardsLeft, isEventReallyStarted) {
  const items = [];
  const subItems = [];

  if (event.status != "COMPLETED") {
    if (player.onSite == false) {
      items.push({
        title: "Mark as checked in",
        action: "checkIn",
        disabled: false,
        textColor: "",
        subItems: [],
        confirmationRequired: false,
        separator: null,
      });
    } else {
      items.push({
        title: "Unmark as checked in",
        action: "checkIn",
        disabled: false,
        textColor: "",
        subItems: [],
        confirmationRequired: false,
        separator: null,
      });
    }

    if (player.isWildcard) {
      items.push({
        title: "Remove wildcard",
        action: "removewildcard",
        disabled: false,
        textColor: "",
        subItems: [],
        confirmationRequired: false,
        separator: null,
      });
    } else if (wildcardsLeft > 0) {
      items.push({
        title: "Make wildcard",
        action: "addwildcard",
        disabled: false,
        textColor: "",
        subItems: [],
        confirmationRequired: false,
        separator: null,
      });
    }
  }

  if (event.tour.paymentType == "MANUAL") {
    if (player.hasPaid) {
      items.push({
        title: "Unmark as paid",
        action: "removepayment",
        disabled: false,
        textColor: "",
        subItems: [],
        confirmationRequired: false,
        separator: null,
      });
    } else {
      items.push({
        title: "Mark as paid",
        action: "addpayment",
        disabled: false,
        textColor: "",
        subItems: [],
        confirmationRequired: false,
        separator: null,
      });
    }
  }
  if (event.tour.divisions.length > 1) {
    event.tour.divisions.forEach((division) => {
      subItems.push({
        title: division.type,
        value: {
          divisionId: division.id,
          playerId: player.id,
        },
        action: "changeDivsion",
        disabled: false,
        textColor: "",
      });
    });

    items.push({
      title: "Change division",
      action: "",
      disabled: false,
      textColor: "",
      subItems: subItems,
      confirmationRequired: false,
      separator: "bottom",
    });
  }
  if (event.status == "CLOSED") {
    items.push({
      title: "Send to waiting list",
      action: "waitinglist",
      disabled: false,
      textColor: "",
      subItems: [],
      confirmationRequired: false,
      separator: null,
    });
  }
  if (isEventReallyStarted == false) {
    if (event.tour.paymentType == "MANUAL") {
      items.push({
        title: "Remove registration",
        action: "removeplayer",
        disabled: false,
        textColor: "#FF0064",
        subItems: [],
        confirmationRequired: false,
        separator: null,
      });
    } else {
      items.push({
        title: player.hasPaid ? "Remove & refund" : "Remove registration",
        action: "removeplayer",
        disabled: false,
        textColor: "#FF0064",
        subItems: [],
        confirmationRequired: false,
        separator: null,
      });
    }
  }

  return items;
}

// Action menu that is used in the waitinglist when the signup method is LIST.

export function ListSignupWaitingListActionMenu(event, player, wildcardsLeft, isEventReallyStarted) {
  const items = [];
  const subItems = [];

  if (isEventReallyStarted == false) {
    items.push({
      title: "Confirm player",
      action: "accept",
      disabled: false,
      textColor: "",
      subItems: [],
      confirmationRequired: false,
      separator: null,
    });
  }

  if (event.status != "COMPLETED") {
    if (event.tour.paymentType == "MANUAL") {
      if (player.hasPaid) {
        items.push({
          title: "Unmark as paid",
          action: "removepayment",
          disabled: false,
          textColor: "",
          subItems: [],
          confirmationRequired: false,
          separator: null,
        });
      } else {
        items.push({
          title: "Mark as paid",
          action: "addpayment",
          disabled: false,
          textColor: "",
          subItems: [],
          confirmationRequired: false,
          separator: null,
        });
        if (isEventReallyStarted == false) {
          items.push({
            title: "Mark as paid & confirm",
            action: "accept-paid",
            disabled: false,
            textColor: "",
            subItems: [],
            confirmationRequired: false,
            separator: null,
          });
        }
      }
    }

    if (isEventReallyStarted == false) {
      if (wildcardsLeft > 0) {
        items.push({
          title: "Give wildcard",
          action: "wildcard",
          disabled: false,
          textColor: "",
          subItems: [],
          confirmationRequired: false,
          separator: null,
        });
      }
    }
  }
  if (event.tour.divisions.length > 1) {
    event.tour.divisions.forEach((division) => {
      subItems.push({
        title: division.type,
        value: {
          divisionId: division.id,
          playerId: player.id,
        },
        action: "changeDivsion",
        disabled: false,
        textColor: "",
      });
    });

    items.push({
      title: "Change division",
      action: "",
      disabled: false,
      textColor: "",
      subItems: subItems,
      confirmationRequired: false,
      separator: "bottom",
    });
  }

  if (isEventReallyStarted == false) {
    if (event.tour.paymentType == "MANUAL") {
      items.push({
        title: "Remove registration",
        action: "remove",
        disabled: false,
        textColor: "#FF0064",
        subItems: [],
        confirmationRequired: false,
        separator: null,
      });
    } else {
      items.push({
        title: player.hasPaid ? "Remove & refund" : "Remove registration",
        action: "remove",
        disabled: false,
        textColor: "#FF0064",
        subItems: [],
        confirmationRequired: false,
        separator: null,
      });
    }
  }

  return items;
}
