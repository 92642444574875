<template>
  <sweet-modal
    :enable-mobile-fullscreen="false"
    ref="playerprofilemanagerpopup"
    @close="nativeClose()"
    hide-close-button
  >
    <div class="close-button" @click="closePopup()">
      <Crossmark />
    </div>
    <div class="avatar">
      <p>
        {{ contactDetails.firstName.charAt(0)
        }}{{ contactDetails.lastname.charAt(0) }}
      </p>
    </div>
    <div class="names">
      <h3 class="profile-firstname">{{ profile.user.firstName }}</h3>
      <h3 class="profile-lastname">{{ profile.user.lastName }}</h3>
    </div>
    <p class="club">
      Club: <b>{{ contactDetails.club || "-" }}</b>
    </p>
    <div class="pdga">
      <p>
        PDGA No: <b>{{ contactDetails.pdgaNumber || "-" }}</b>
      </p>
      <p class="bullet-separator" v-if="contactDetails.pdgaRating">•</p>
      <p v-if="contactDetails.pdgaRating">
        Rating: <b>{{ contactDetails.pdgaRating || "-" }}</b>
      </p>
    </div>

    <p class="phone">
      Phone: <b>{{ contactDetails.phone || "-" }}</b>
    </p>
    <p>
      Email:
      <b
        class="copy-email"
        @click="
          copyEmail(
            contactDetails.firstName,
            contactDetails.lastname,
            contactDetails.email
          )
        "
        >{{ contactDetails.email }}</b
      >
    </p>
    <div class="separator"></div>
    <div class="division">
      <p>
        Division: <b>{{ profile.division.name }}</b> ({{
          profile.division.type
        }})
      </p>
      <TjingToolTip
        :text="'Wildcard'"
        :hideOnDesktop="false"
        v-if="profile.isWildcard"
      >
        <Wildcard class="wildcard" />
      </TjingToolTip>
    </div>

    <p>
      Registered: <b>{{ detailedRegTime(profile.createdAt) }}</b>
    </p>

    <div class="paid-checkin">
      <On v-if="profile.hasPaid" />
      <Off v-else />
      <p class="paid"><b>Paid</b></p>
      <On v-if="profile.player && profile.player.onSite" />
      <Off v-else />
      <p><b>Checked in</b></p>
    </div>
    <div
      class="reg-note"
      v-if="
        profile.message ||
        (profile.registration && profile.registration.message)
      "
    >
      <div class="flap"></div>
      <p>
        <b>"{{ profile.message || profile.registration.message }}"</b>
      </p>
    </div>
  </sweet-modal>
</template>

<script>
import moment from "moment";
import Wildcard from "@/assets/icons/Wildcard";
import On from "@/assets/icons/On";
import Off from "@/assets/icons/Off";
import Crossmark from "@/assets/icons/Crossmark";

export default {
  name: "PlayerProfileManagerPopup",
  props: ["profile", "contactDetails"],
  components: {
    On,
    Off,
    Wildcard,
    Crossmark,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    copyEmail(firstName, lastName, email) {
      try {
        const formattedEmail = `${firstName} ${lastName} <${email}>`;
        navigator.clipboard.writeText(formattedEmail);

        this.$store.dispatch("showMessage", {
          show: true,
          message: "Email copied",
          type: "success",
        });
      } catch (err) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Unable to copy",
          type: "error",
        });
      }
    },
    detailedRegTime(timeStamp) {
      return moment(timeStamp).format("D MMM HH:mm:ss");
    },
    nativeClose() {
      setTimeout(() => {
        this.$emit("close");
      }, 300);
    },
    closePopup() {
      this.$refs.playerprofilemanagerpopup.close();
      setTimeout(() => {
        this.$emit("close");
      }, 300);
    },
  },
  mounted() {
    this.$refs.playerprofilemanagerpopup.open();
  },
  beforeUnmount() {},
  beforeDestroy() {},
};
</script>
<style lang="scss">
.sweet-modal .sweet-box-actions .sweet-action-close svg {
  width: 32px;
  height: 32px;
}
@media (min-width: 992px) {
  .sweet-modal-overlay {
    .sweet-modal {
      max-width: 425px !important;
    }
  }
}
</style>
<style lang="scss" scoped>
@import "@/styles/Typography";

.close-button {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all ease 0.3s;
  cursor: pointer;

  &:hover {
    background: $mist;
  }
  svg {
    width: 18px;
    height: 18px;
  }
}

.copy-email {
  color: $ocean;
  cursor: pointer;
}
.reg-note {
  background: #fff4d9;
  padding: 18px 32px;
  position: relative;
  margin: 32px 12px 0 12px;
  border-radius: 6px;
  overflow: hidden;

  ::after {
    content: "";
    width: 18px;
    height: 18px;
    right: 1px;
    top: 1px;
    background: linear-gradient(
      45deg,
      desaturate(darken(#fff4d9, 5), 60%) 0,
      desaturate(darken(#fff4d9, 5), 60%) 50%,
      white 50.1%,
      white 100%
    );
    position: absolute;
    z-index: 2;
    border-bottom-left-radius: 6px;
  }

  ::before {
    content: "";
    width: 26px;
    height: 26px;
    right: -12px;
    top: -12px;
    background: white;
    position: absolute;
    transform: rotate(45deg);
    z-index: 1;
  }

  p {
    margin: 0;
  }
}
.avatar {
  height: 72px;
  width: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $sleet;
  background: $mist;
  border-radius: 50%;
  margin: 0 auto;

  p {
    font-size: 36px !important;
    @include Gilroy-Bold;
    color: $blizzard;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 0.025em;
  }
}
.names {
  margin: 10px 0 24px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  h3 {
    font-size: 24px !important;
    margin-bottom: 0 !important;
    text-transform: uppercase;
  }
}
.profile-firstname {
  margin-right: 8px;
}
.profile-lastname {
  @include Gilroy-Regular;
}

.club {
  margin-top: 24px;
}

.pdga {
  display: flex;
  justify-content: center;

  .bullet-separator {
    margin-left: 6px;
    margin-right: 6px;
  }
}

.separator {
  width: 50px;
  height: 1px;
  background: $sleet;
  margin: 24px auto;
}
.division {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;

  p {
    margin: 0;
  }

  svg {
    width: 18px;
    height: 18px;
    margin-left: 6px;
  }

  .wildcard {
    margin-bottom: 2px;
  }
}
p {
  margin-top: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.email {
  cursor: pointer;
  color: $ocean;
}

.paid-checkin {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 14px;

  svg {
    margin-right: 6px;
    width: 18px;
    height: 18px;
  }

  p {
    margin: 0;
  }

  .paid {
    margin-right: 24px;
  }
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .names {
    margin-top: 24px;
  }
  .sweet-modal-overlay {
    .sweet-modal {
      max-width: 320px !important;
    }
  }
}
</style>
