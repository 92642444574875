var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('sweet-modal',{ref:"playerprofilemanagerpopup",attrs:{"enable-mobile-fullscreen":false,"hide-close-button":""},on:{"close":function($event){return _vm.nativeClose()}}},[_c('div',{staticClass:"close-button",on:{"click":function($event){return _vm.closePopup()}}},[_c('Crossmark')],1),_c('div',{staticClass:"avatar"},[_c('p',[_vm._v(" "+_vm._s(_vm.contactDetails.firstName.charAt(0))+_vm._s(_vm.contactDetails.lastname.charAt(0))+" ")])]),_c('div',{staticClass:"names"},[_c('h3',{staticClass:"profile-firstname"},[_vm._v(_vm._s(_vm.profile.user.firstName))]),_c('h3',{staticClass:"profile-lastname"},[_vm._v(_vm._s(_vm.profile.user.lastName))])]),_c('p',{staticClass:"club"},[_vm._v(" Club: "),_c('b',[_vm._v(_vm._s(_vm.contactDetails.club || "-"))])]),_c('div',{staticClass:"pdga"},[_c('p',[_vm._v(" PDGA No: "),_c('b',[_vm._v(_vm._s(_vm.contactDetails.pdgaNumber || "-"))])]),(_vm.contactDetails.pdgaRating)?_c('p',{staticClass:"bullet-separator"},[_vm._v("•")]):_vm._e(),(_vm.contactDetails.pdgaRating)?_c('p',[_vm._v(" Rating: "),_c('b',[_vm._v(_vm._s(_vm.contactDetails.pdgaRating || "-"))])]):_vm._e()]),_c('p',{staticClass:"phone"},[_vm._v(" Phone: "),_c('b',[_vm._v(_vm._s(_vm.contactDetails.phone || "-"))])]),_c('p',[_vm._v(" Email: "),_c('b',{staticClass:"copy-email",on:{"click":function($event){return _vm.copyEmail(
          _vm.contactDetails.firstName,
          _vm.contactDetails.lastname,
          _vm.contactDetails.email
        )}}},[_vm._v(_vm._s(_vm.contactDetails.email))])]),_c('div',{staticClass:"separator"}),_c('div',{staticClass:"division"},[_c('p',[_vm._v(" Division: "),_c('b',[_vm._v(_vm._s(_vm.profile.division.name))]),_vm._v(" ("+_vm._s(_vm.profile.division.type)+") ")]),(_vm.profile.isWildcard)?_c('TjingToolTip',{attrs:{"text":'Wildcard',"hideOnDesktop":false}},[_c('Wildcard',{staticClass:"wildcard"})],1):_vm._e()],1),_c('p',[_vm._v(" Registered: "),_c('b',[_vm._v(_vm._s(_vm.detailedRegTime(_vm.profile.createdAt)))])]),_c('div',{staticClass:"paid-checkin"},[(_vm.profile.hasPaid)?_c('On'):_c('Off'),_c('p',{staticClass:"paid"},[_c('b',[_vm._v("Paid")])]),(_vm.profile.player && _vm.profile.player.onSite)?_c('On'):_c('Off'),_c('p',[_c('b',[_vm._v("Checked in")])])],1),(
      _vm.profile.message ||
      (_vm.profile.registration && _vm.profile.registration.message)
    )?_c('div',{staticClass:"reg-note"},[_c('div',{staticClass:"flap"}),_c('p',[_c('b',[_vm._v("\""+_vm._s(_vm.profile.message || _vm.profile.registration.message)+"\"")])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }