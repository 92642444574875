<template>
  <div v-if="!loading">
    <input class="d-none" type="text" :value="playerEmailLists.all" />
    <input class="d-none" type="text" :value="playerEmailLists.confirmed" />
    <input class="d-none" type="text" :value="playerEmailLists.waiting" />

    <div class="event-players">
      <div class="section summary">
        <div class="summary-container">
          <div class="summary-item">
            <SpotsLeft color="grey" />
            <p>{{ eventData.maxSpots }} <span>Max spots</span></p>
          </div>
          <div class="summary-item">
            <SpotsLeft />
            <p>
              {{ eventData.maxSpots - confirmedPlayers.length }}
              <span>Available spots</span>
            </p>
          </div>
          <div class="summary-item">
            <On color="ocean" />
            <p>{{ confirmedPlayers.length }} <span>Confirmed players</span></p>
          </div>
          <div class="summary-item">
            <WaitingList />
            <p>{{ waitingList.length }} <span>Waiting players</span></p>
          </div>
          <div class="summary-item">
            <Wildcard />
            <p>{{ wildCardsLeft }} <span>Wildcards left</span></p>
          </div>
        </div>
      </div>
    </div>

    <div class="row event-players">
      <div class="section">
        <h5>
          <On color="ocean" class="confirmed-icon" />
          Confirmed
          <span>({{ eventData.players.length }})</span>
        </h5>
        <div
          v-for="division in eventData.tour.divisions"
          class="division-container"
          :key="division.type"
        >
          <div class="header confirmed">
            <p>
              <b>{{ division.name }}</b>
              ({{ playersInDivisionCount(division) }})
            </p>
          </div>
          <table class="confirmed-table">
            <thead class="confirmed">
              <tr>
                <th class="position"></th>

                <th
                  class="player"
                  @click="
                    sortConfirmedListBy = 'firstName';
                    sortConfirmedListAscending = !sortConfirmedListAscending;
                  "
                >
                  <span :class="{ active: sortConfirmedListBy == 'firstName' }"
                    >Player</span
                  >
                </th>
                <th
                  class="on-site"
                  @click="
                    sortConfirmedListBy = 'onSite';
                    sortConfirmedListAscending = !sortConfirmedListAscending;
                  "
                >
                  <span
                    :class="{ active: sortConfirmedListBy == 'onSite' }"
                  ></span>
                </th>
                <th
                  class="pdga-number d-none d-sm-table-cell"
                  @click="
                    sortConfirmedListBy = 'pdgaNumber';
                    sortConfirmedListAscending = !sortConfirmedListAscending;
                  "
                >
                  <span :class="{ active: sortConfirmedListBy == 'pdgaNumber' }"
                    >PDGA</span
                  >
                </th>
                <th
                  class="pdga-rating"
                  @click="
                    sortConfirmedListBy = 'pdgaRating';
                    sortConfirmedListAscending = !sortConfirmedListAscending;
                  "
                >
                  <span
                    :class="{ active: sortConfirmedListBy == 'pdgaRating' }"
                  ></span>
                </th>
                <th
                  class="payment"
                  @click="
                    sortConfirmedListBy = 'payment';
                    sortConfirmedListAscending = !sortConfirmedListAscending;
                  "
                >
                  <span :class="{ active: sortConfirmedListBy == 'payment' }"
                    >Paid</span
                  >
                </th>
                <th
                  class="reg-time d-none d-sm-table-cell"
                  @click="
                    sortConfirmedListBy = 'createdAt';
                    sortConfirmedListAscending = !sortConfirmedListAscending;
                  "
                >
                  <span :class="{ active: sortConfirmedListBy == 'createdAt' }"
                    >Registered</span
                  >
                </th>
                <th
                  class="wildcard d-none d-sm-table-cell"
                  @click="
                    sortConfirmedListBy = 'isWildcard';
                    sortConfirmedListAscending = !sortConfirmedListAscending;
                  "
                >
                  <span
                    :class="{ active: sortConfirmedListBy == 'isWildcard' }"
                  ></span>
                </th>
                <th class="action-menu">
                  <span></span>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(player, playerIndex) in playersInDivision(division)"
                :key="player.id"
              >
                <td class="position">{{ playerIndex + 1 }}</td>

                <td class="player">
                  <div class="player-info" @click="openPlayerProfile(player)">
                    <p>
                      {{ player.user.firstName }} {{ player.user.lastName }}
                    </p>
                    <Notes class="notes" v-if="player.registration.message" />
                  </div>
                </td>
                <td class="on-site">
                  <img
                    v-if="player.onSite"
                    src="@/assets/img/paid-icon-midnight-mob.svg"
                    alt
                  />
                </td>
                <td class="pdga-number d-none d-sm-table-cell">
                  <a
                    :href="
                      'https://www.pdga.com/player/' +
                      player.user.profile.pdgaNumber
                    "
                    target="_blank"
                    >{{ player.user.profile.pdgaNumber }}</a
                  >
                </td>
                <td class="pdga-rating">
                  <a
                    :href="
                      'https://www.pdga.com/player/' +
                      player.user.profile.pdgaNumber +
                      '/details'
                    "
                    target="_blank"
                    >{{ player.user.profile.pdgaRating }}</a
                  >
                </td>
                <td class="payment">
                  <img
                    v-if="player.hasPaid"
                    src="@/assets/img/paid-icon-midnight-mob.svg"
                  />
                </td>
                <td class="reg-time d-none d-sm-table-cell">
                  <TjingToolTip
                    :text="detailedRegTime(player.registration.createdAt)"
                    :hideOnDesktop="false"
                  >
                    {{ regTime(player.registration.createdAt) }}
                  </TjingToolTip>
                </td>
                <td class="wildcard d-none d-sm-table-cell">
                  <img
                    v-if="player.isWildcard"
                    src="@/assets/img/wildcard-gold.svg"
                  />
                </td>
                <td class="action-menu">
                  <ActionMenu
                    v-if="confirmedPlayersActionMenuToUse(player).length > 0"
                    :items="confirmedPlayersActionMenuToUse(player)"
                    @addwildcard="wildcardPlayer(player, true)"
                    @removewildcard="wildcardPlayer(player, false)"
                    @addpayment="togglePayment(player.user.id, true)"
                    @removepayment="togglePayment(player.user.id, false)"
                    @waitinglist="playerToWaitinglist = player"
                    @removeplayer="playerToRemove = player"
                    @checkIn="checkIn(player)"
                    @changeDivsion="changePlayerDivision"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div
      class="row event-players"
      v-if="eventData.waitinglistType.type == 'DIVISION'"
    >
      <div class="section">
        <h5>
          <WaitingList />
          Waiting
          <span>({{ waitingList.length }})</span>
        </h5>
        <div
          v-for="division in eventData.tour.divisions"
          class="division-container"
          :key="division.type"
        >
          <div class="header waiting">
            <p>
              <b>{{ division.name }}</b>
              ({{ playersInWaitingListDivisionCount(division) }})
            </p>
          </div>

          <table class="waiting-table">
            <thead class="waiting">
              <tr>
                <th class="position"></th>
                <th class="check-box" v-if="!isEventReallyStarted">
                  <DefaultCheckBox
                    :value="division.selectAll"
                    :disabled="false"
                    @input="selectAllInDivision($event, division)"
                  />
                </th>
                <th
                  @click="
                    sortWaitingListBy = 'firstName';
                    sortWaitingListAscending = !sortWaitingListAscending;
                  "
                  class="player"
                >
                  <span :class="{ active: sortWaitingListBy == 'firstName' }"
                    >Player</span
                  >
                </th>
                <th
                  @click="
                    sortWaitingListBy = 'pdgaNumber';
                    sortWaitingListAscending = !sortWaitingListAscending;
                  "
                  class="pdga-number d-none d-sm-table-cell"
                >
                  <span :class="{ active: sortWaitingListBy == 'pdgaNumber' }"
                    >PDGA</span
                  >
                </th>
                <th
                  @click="
                    sortWaitingListBy = 'pdgaRating';
                    sortWaitingListAscending = !sortWaitingListAscending;
                  "
                  class="pdga-rating"
                >
                  <span
                    :class="{ active: sortWaitingListBy == 'pdgaRating' }"
                  ></span>
                </th>
                <th
                  @click="
                    sortWaitingListBy = 'payment';
                    sortWaitingListAscending = !sortWaitingListAscending;
                  "
                  class="payment"
                >
                  <span :class="{ active: sortWaitingListBy == 'payment' }"
                    >Paid</span
                  >
                </th>
                <th
                  @click="
                    sortWaitingListBy = 'createdAt';
                    sortWaitingListAscending = !sortWaitingListAscending;
                  "
                  class="reg-time d-none d-sm-table-cell"
                >
                  <span
                    :class="{
                      active: sortWaitingListBy == 'createdAt',
                    }"
                    >Registered</span
                  >
                </th>
                <th class="action-menu">
                  <span></span>
                </th>
              </tr>
            </thead>

            <transition-group name="flip" tag="tbody">
              <tr
                v-for="(player, playerIndex) in playersInWaitingListDivision(
                  division
                )"
                :key="player.id"
              >
                <td class="position">{{ playerIndex + 1 }}</td>
                <td class="check-box" v-if="!isEventReallyStarted">
                  <DefaultCheckBox
                    :value="player.selected"
                    :disabled="false"
                    @input="player.selected = $event"
                  />
                </td>
                <td class="player">
                  <div class="player-info" @click="openPlayerProfile(player)">
                    <p>
                      {{ player.user.firstName }} {{ player.user.lastName }}
                    </p>
                    <Notes class="notes" v-if="player.message" />
                  </div>
                </td>
                <td class="pdga-number d-none d-sm-table-cell">
                  <a
                    :href="
                      'https://www.pdga.com/player/' +
                      player.user.profile.pdgaNumber
                    "
                    target="_blank"
                    >{{ player.user.profile.pdgaNumber }}</a
                  >
                </td>
                <td class="pdga-rating">
                  <a
                    :href="
                      'https://www.pdga.com/player/' +
                      player.user.profile.pdgaNumber
                    "
                    target="_blank"
                    >{{ player.user.profile.pdgaRating }}</a
                  >
                </td>
                <td class="payment">
                  <img
                    v-if="player.hasPaid"
                    src="@/assets/img/paid-icon-midnight-mob.svg"
                  />
                </td>
                <td class="reg-time d-none d-sm-table-cell">
                  <TjingToolTip
                    :text="detailedRegTime(player.createdAt)"
                    :hideOnDesktop="false"
                  >
                    {{ regTime(player.createdAt) }}
                  </TjingToolTip>
                </td>
                <td class="action-menu">
                  <ActionMenu
                    v-if="waitingListActionMenuToUse(player).length > 0"
                    :items="waitingListActionMenuToUse(player)"
                    @accept="acceptPlayer(player)"
                    @accept-paid="acceptPlayerAndPayment(player, true)"
                    @addpayment="togglePayment(player.user.id, true)"
                    @removepayment="togglePayment(player.user.id, false)"
                    @wildcard="acceptAndWildcardPlayer(player)"
                    @wildcard-paid="wildcardPlayerAndPayment(player, true)"
                    @remove="playerToRemove = player"
                    @changeDivsion="changeRegistrationDivision"
                  />
                </td>
              </tr>
            </transition-group>
          </table>
        </div>
        <span class="col-12 p-0" v-if="!isEventReallyStarted">
          <div
            class="all-actions-container mt-5"
            v-if="selectedPlayers.length > 0"
          >
            <p>{{ selectedPlayers.length }} players selected. Mark as:</p>
            <span>
              <StandardButton
                v-if="!stripeManagedTour"
                title="Paid"
                type="standard"
                @click="markAllAsPaid()"
              />
              <StandardButton
                v-if="
                  enoughSpaceToConfirmSelectedPlayers &&
                  eventData.tour.registrationMethod == 'LIST'
                "
                title="Confirmed"
                type="standard"
                @click="acceptAllPlayers()"
              />
              <StandardButton
                v-else
                title="Confirmed"
                type="standard"
                :disabled="true"
              />

              <StandardButton
                v-if="
                  enoughSpaceToConfirmSelectedPlayers &&
                  eventData.tour.registrationMethod == 'LIST' &&
                  !stripeManagedTour
                "
                title="Paid and Confirmed"
                type="standard"
                @click="acceptAndPaidAllPlayers()"
              />

              <StandardButton
                v-if="
                  !enoughSpaceToConfirmSelectedPlayers &&
                  eventData.tour.registrationMethod == 'LIST' &&
                  !stripeManagedTour
                "
                title="Paid and Confirmed"
                type="standard"
                :disabled="true"
              />
              <StandardButton
                title="Cancelled"
                type="standard"
                @click="markAllAsCancelled()"
              />
            </span>
            <p
              class="full-row-of-text"
              v-if="!enoughSpaceToConfirmSelectedPlayers"
            >
              The number of selected players currently exceeds your
              <i>max players</i> limit. The <i>max players</i> limit can be
              found under your event settings.
            </p>
          </div>
        </span>
      </div>
    </div>
    <div class="row event-players" v-else>
      <div class="section">
        <h5>
          <WaitingList />
          Waiting
          <span>({{ waitingList.length }})</span>
        </h5>
        <div class="division-container">
          <div class="header waiting">
            <p>
              <b>All divisions</b>
              ({{ waitingList.length }})
            </p>
          </div>

          <table class="waiting-table">
            <thead class="waiting">
              <tr>
                <th class="position"></th>
                <th class="check-box">
                  <DefaultCheckBox
                    :value="eventData.allWaitingPlayersSelected"
                    :disabled="false"
                    @input="selectAllInWaitinglist($event)"
                  />
                </th>
                <th
                  @click="
                    sortWaitingListBy = 'firstName';
                    sortWaitingListAscending = !sortWaitingListAscending;
                  "
                  class="player"
                >
                  <span :class="{ active: sortWaitingListBy == 'firstName' }"
                    >Player</span
                  >
                </th>
                <th
                  @click="
                    sortWaitingListBy = 'division';
                    sortWaitingListAscending = !sortWaitingListAscending;
                  "
                  class="division"
                >
                  <span :class="{ active: sortWaitingListBy == 'division' }"
                    >Division</span
                  >
                </th>
                <th
                  @click="
                    sortWaitingListBy = 'pdgaNumber';
                    sortWaitingListAscending = !sortWaitingListAscending;
                  "
                  class="pdga-number d-none d-sm-table-cell"
                >
                  <span :class="{ active: sortWaitingListBy == 'pdgaNumber' }"
                    >PDGA</span
                  >
                </th>
                <th
                  @click="
                    sortWaitingListBy = 'pdgaRating';
                    sortWaitingListAscending = !sortWaitingListAscending;
                  "
                  class="pdga-rating"
                >
                  <span
                    :class="{ active: sortWaitingListBy == 'pdgaRating' }"
                  ></span>
                </th>
                <th
                  @click="
                    sortWaitingListBy = 'payment';
                    sortWaitingListAscending = !sortWaitingListAscending;
                  "
                  class="payment"
                >
                  <span :class="{ active: sortWaitingListBy == 'payment' }"
                    >Paid</span
                  >
                </th>
                <th
                  @click="
                    sortWaitingListBy = 'createdAt';
                    sortWaitingListAscending = !sortWaitingListAscending;
                  "
                  class="reg-time d-none d-sm-table-cell"
                >
                  <span
                    :class="{
                      active: sortWaitingListBy == 'createdAt',
                    }"
                    >Registered</span
                  >
                </th>
                <th class="action-menu">
                  <span></span>
                </th>
              </tr>
            </thead>

            <transition-group name="flip" tag="tbody">
              <tr v-for="(player, playerIndex) in waitingList" :key="player.id">
                <td class="position">{{ playerIndex + 1 }}</td>
                <td class="check-box">
                  <DefaultCheckBox
                    :value="player.selected"
                    :disabled="false"
                    @input="player.selected = $event"
                  />
                </td>
                <td class="player">
                  <div class="player-info" @click="openPlayerProfile(player)">
                    <p>
                      {{ player.user.firstName }} {{ player.user.lastName }}
                    </p>
                    <Notes class="notes" v-if="player.message" />
                  </div>
                </td>
                <td class="division">
                  {{ player.division.name }}
                </td>
                <td class="pdga-number d-none d-sm-table-cell">
                  <a
                    :href="
                      'https://www.pdga.com/player/' +
                      player.user.profile.pdgaNumber
                    "
                    target="_blank"
                    >{{ player.user.profile.pdgaNumber }}</a
                  >
                </td>
                <td class="pdga-rating">
                  <a
                    :href="
                      'https://www.pdga.com/player/' +
                      player.user.profile.pdgaNumber
                    "
                    target="_blank"
                    >{{ player.user.profile.pdgaRating }}</a
                  >
                </td>
                <td class="payment">
                  <img
                    v-if="player.hasPaid"
                    src="@/assets/img/paid-icon-midnight-mob.svg"
                  />
                </td>
                <td class="reg-time d-none d-sm-table-cell">
                  <TjingToolTip
                    :text="detailedRegTime(player.createdAt)"
                    :hideOnDesktop="false"
                  >
                    {{ regTime(player.createdAt) }}
                  </TjingToolTip>
                </td>
                <td class="action-menu">
                  <ActionMenu
                    v-if="waitingListActionMenuToUse(player).length > 0"
                    :items="waitingListActionMenuToUse(player)"
                    @accept="acceptPlayer(player)"
                    @accept-paid="acceptPlayerAndPayment(player, true)"
                    @addpayment="togglePayment(player.user.id, true)"
                    @removepayment="togglePayment(player.user.id, false)"
                    @wildcard="acceptAndWildcardPlayer(player)"
                    @wildcard-paid="wildcardPlayerAndPayment(player, true)"
                    @remove="playerToRemove = player"
                    @changeDivsion="changeRegistrationDivision"
                  />
                </td>
              </tr>
            </transition-group>
          </table>
        </div>
        <span class="col-12 p-0" v-if="eventData.status == 'CLOSED'">
          <div
            class="all-actions-container mt-5"
            v-if="selectedPlayers.length > 0"
          >
            <p>{{ selectedPlayers.length }} players selected. Mark as:</p>
            <span>
              <StandardButton
                v-if="!stripeManagedTour"
                title="Paid"
                type="standard"
                @click="markAllAsPaid()"
              />
              <StandardButton
                v-if="
                  enoughSpaceToConfirmSelectedPlayers &&
                  eventData.tour.registrationMethod == 'LIST'
                "
                title="Confirmed"
                type="standard"
                @click="acceptAllPlayers()"
              />
              <StandardButton
                v-else
                title="Confirmed"
                type="standard"
                :disabled="true"
              />

              <StandardButton
                v-if="
                  enoughSpaceToConfirmSelectedPlayers &&
                  eventData.tour.registrationMethod == 'LIST' &&
                  !stripeManagedTour
                "
                title="Paid and Confirmed"
                type="standard"
                @click="acceptAndPaidAllPlayers()"
              />

              <StandardButton
                v-if="
                  !enoughSpaceToConfirmSelectedPlayers &&
                  eventData.tour.registrationMethod == 'LIST' &&
                  !stripeManagedTour
                "
                title="Paid and Confirmed"
                type="standard"
                :disabled="true"
              />
              <StandardButton
                title="Cancelled"
                type="standard"
                @click="markAllAsCancelled()"
              />
            </span>
            <p
              class="full-row-of-text"
              v-if="!enoughSpaceToConfirmSelectedPlayers"
            >
              The number of selected players currently exceeds your
              <i>max players</i> limit. The <i>max players</i> limit can be
              found under your event settings.
            </p>
          </div>
        </span>
      </div>
    </div>
    <div class="row event-players">
      <div class="section">
        <h5>
          <Remove />
          Cancelled
          <span>({{ canceledRegistrations.length }})</span>
        </h5>
        <div
          v-for="division in eventData.tour.divisions"
          class="division-container"
          :key="division.type"
        >
          <div class="header cancelled">
            <p>
              <b>{{ division.name }}</b>
              ({{ canceledRegistrationsDivisionCount(division) }})
            </p>
          </div>
          <table class="cancelled-table">
            <thead class="cancelled">
              <tr>
                <th class="position"></th>
                <th
                  @click="
                    sortCancelledListBy = 'firstName';
                    sortCancelledListAscending = !sortCancelledListAscending;
                  "
                  class="player pl-1 pl-sm-0"
                >
                  <span :class="{ active: sortCancelledListBy == 'firstName' }"
                    >Player</span
                  >
                </th>
                <th
                  @click="
                    sortCancelledListBy = 'pdgaNumber';
                    sortCancelledListAscending = !sortCancelledListAscending;
                  "
                  class="pdga-number d-none d-sm-table-cell"
                >
                  <span :class="{ active: sortCancelledListBy == 'pdgaNumber' }"
                    >PDGA</span
                  >
                </th>
                <th
                  @click="
                    sortCancelledListBy = 'pdgaRating';
                    sortCancelledListAscending = !sortCancelledListAscending;
                  "
                  class="pdga-rating"
                >
                  <span
                    :class="{ active: sortCancelledListBy == 'pdgaRating' }"
                  ></span>
                </th>
                <th
                  @click="
                    sortCancelledListBy = 'payment';
                    sortCancelledListAscending = !sortCancelledListAscending;
                  "
                  class="payment"
                  v-if="eventData.tour.paymentType == 'MANUAL'"
                >
                  <span :class="{ active: sortCancelledListBy == 'payment' }"
                    >Paid</span
                  >
                </th>
                <th
                  @click="
                    sortCancelledListBy = 'payment';
                    sortCancelledListAscending = !sortCancelledListAscending;
                  "
                  class="refund"
                  v-if="eventData.tour.paymentType == 'MANAGED'"
                >
                  <span :class="{ active: sortCancelledListBy == 'payment' }"
                    >Refunded</span
                  >
                </th>
                <th
                  @click="
                    sortCancelledListBy = 'statusUpdatedAt';
                    sortCancelledListAscending = !sortCancelledListAscending;
                  "
                  class="reg-time d-none d-sm-table-cell"
                >
                  <span
                    :class="{
                      active: sortCancelledListBy == 'statusUpdatedAt',
                    }"
                    >Cancelled</span
                  >
                </th>
                <th class="action-menu">
                  <span></span>
                </th>
              </tr>
            </thead>
            <transition-group name="flip" tag="tbody">
              <tr
                v-for="(player, playerIndex) in cancelledRegistrationDivision(
                  division
                )"
                :key="player.id"
              >
                <td class="position larger">{{ playerIndex + 1 }}</td>
                <td class="player pl-1 pl-sm-0">
                  <div class="player-info" @click="openPlayerProfile(player)">
                    <p>
                      {{ player.user.firstName }} {{ player.user.lastName }}
                    </p>
                    <Notes class="notes" v-if="player.message" />
                  </div>
                </td>
                <td class="pdga-number d-none d-sm-table-cell">
                  {{ player.user.profile.pdgaNumber }}
                </td>
                <td class="pdga-rating">
                  <a
                    :href="
                      'https://www.pdga.com/player/' +
                      player.user.profile.pdgaNumber
                    "
                    target="_blank"
                    >{{ player.user.profile.pdgaRating }}</a
                  >
                </td>
                <td
                  class="payment"
                  v-if="eventData.tour.paymentType == 'MANUAL'"
                >
                  <img
                    v-if="player.hasPaid"
                    src="@/assets/img/paid-icon-midnight-mob.svg"
                  />
                </td>
                <td
                  class="payment"
                  v-if="eventData.tour.paymentType == 'MANAGED'"
                >
                  <img
                    v-if="!player.hasPaid"
                    src="@/assets/img/paid-icon-midnight-mob.svg"
                  />
                </td>
                <td class="reg-time d-none d-sm-table-cell">
                  <TjingToolTip
                    :text="detailedRegTime(player.statusUpdatedAt)"
                    :hideOnDesktop="false"
                  >
                    {{ regTime(player.statusUpdatedAt) }}
                  </TjingToolTip>
                </td>
                <td class="action-menu">
                  <ActionMenu
                    v-if="
                      cancelledRegistrationsActionMenuToUse(player).length > 0
                    "
                    :items="cancelledRegistrationsActionMenuToUse(player)"
                    @addpayment="togglePayment(player.user.id, true)"
                    @removepayment="togglePayment(player.user.id, false)"
                    @waitinglist="playerToWaitinglist = player"
                  />
                </td>
              </tr>
            </transition-group>
          </table>
        </div>
      </div>
    </div>
    <!--<div class="event-players">
      <div class="section">
        <h5 class="small-margin">Add players not registered</h5>
        <div class="content add-players">
          <p>
            Add a player to this event by adding their email and selecting a
            division. They will be confirmed automatically after you add them.
            This is only possible before you close the first round.
          </p>
          <div class="add-section">
            <input
              type="text"
              placeholder="Add user's email"
              class="add-input"
              :disabled="aRoundIsCompleted"
              @input="addPlayerToEventError = false"
              :class="{ error: addPlayerToEventError }"
              @keypress.enter="verifyaddUserToEvent()"
              v-model="addPlayerToEventEmail"
            />
            <select
              class="division-select"
              v-model="addPlayerToEventDivision"
              :disabled="aRoundIsCompleted"
            >
              <option :value="null">Select division</option>
              <option
                :value="division.id"
                v-for="division in eventData.tour.divisions"
              >
                {{ division.name }}
              </option>
            </select>
            <StandardSolidButton
              class="add-player-action"
              :desktop-fluidity="true"
              title="Add player"
              :disabled="
                !emailAndDivisionValid(
                  addPlayerToEventEmail,
                  addPlayerToEventDivision
                )
              "
              :fluid="false"
              @click="verifyaddUserToEvent()"
            />
            <div class="add-tip">
              <Tip />
              <p>A user’s email can be found on their Tjing profile page.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    -->
    <div class="row event-players">
      <div class="section">
        <h5 class="small-margin">Export and copy</h5>
        <div class="content copy-section">
          <h6>Export player details</h6>
          <p>
            Export a comma separated file (CSV) with names, emails, divisions,
            payment statuses and PDGA numbers.
          </p>
          <button class="export-button" @click="downloadRegistrations()">
            Export players
          </button>
        </div>
        <div class="content copy-section">
          <h6>Copy player emails</h6>
          <div class="copy-container">
            <p>
              Copy emails to your clipboard and paste them into your email app
              for quick communication.
            </p>
            <TjingCopyPlayerEmailsWidget
              position="bottom-end"
              text="Copy emails"
              :registrations="eventData.registrations"
            />
          </div>
        </div>
      </div>
    </div>
    <PlayerProfileManagerPopup
      :contactDetails="playersContactDetails(viewedPlayerProfile)"
      :profile="viewedPlayerProfile"
      v-if="viewedPlayerProfile != null"
      @close="viewedPlayerProfile = null"
    />
    <sweet-modal
      :enable-mobile-fullscreen="false"
      blocking
      ref="waitinglistplayer"
      hide-close-button
    >
      <template v-if="playerToWaitinglist">
        <h3>Waiting list</h3>
        <p>
          Move {{ playerToWaitinglist.user.firstName }} to the waiting list?
        </p>
        <div class="buttons">
          <StandardSolidButton
            title="Cancel"
            :fluid="false"
            @click="
              playerToWaitinglist = null;
              $refs.removeregistration.close();
            "
          />
          <StandardBorderedButton
            title="Move"
            :fluid="false"
            @click="movePlayerToWaitinglist(playerToWaitinglist)"
          />
        </div>
      </template>
    </sweet-modal>
    <sweet-modal
      :enable-mobile-fullscreen="false"
      blocking
      ref="removeregistration"
      hide-close-button
    >
      <template v-if="playerToRemove">
        <h3>Cancel registration</h3>
        <p>
          This will remove {{ playerToRemove.user.firstName }} from the event.
        </p>
        <div class="buttons">
          <StandardSolidButton
            title="Cancel"
            :fluid="false"
            @click="
              playerToRemove = null;
              $refs.removeregistration.close();
            "
          />
          <StandardBorderedButton
            title="Remove"
            :fluid="false"
            @click="removePlayer(playerToRemove)"
          />
        </div>
      </template>
    </sweet-modal>
  </div>
</template>

<script>
import moment from "moment";
import Wildcard from "@/assets/icons/Wildcard";
import PlayerProfileManagerPopup from "@/components/PlayerProfileManagerPopup";
import ActionMenu from "@/components/ActionMenu.vue";
import DefaultCheckBox from "@/components/UIElements/DefaultCheckBox";
import StandardButton from "@/components/UIElements/StandardButton";
import StandardSolidButton from "@/components/UIElements/StandardSolidButton";
import StandardBorderedButton from "@/components/UIElements/StandardBorderedButton";
import Remove from "@/assets/icons/Remove";
import WaitingList from "@/assets/icons/WaitingList";
import On from "@/assets/icons/On";

import SpotsLeft from "@/assets/icons/SpotsLeft";
import Notes from "@/assets/icons/Notes";
import TjingCopyPlayerEmailsWidget from "@/components/TjingCopyPlayerEmailsWidget";
import validator from "validator";

import {
  GroupSignupConfirmedPlayersActionMenu,
  ListSignupConfirmedPlayersActionMenu,
  GroupSignupWaitingListActionMenu,
  ListSignupWaitingListActionMenu,
  CancelledRegistrationsActionMenu,
} from "@/functions/GroupSignupActionMenus";

export default {
  name: "EventPlayers",
  components: {
    ActionMenu,
    DefaultCheckBox,
    StandardButton,
    StandardSolidButton,
    On,
    Remove,
    WaitingList,
    SpotsLeft,
    Wildcard,
    StandardBorderedButton,
    TjingCopyPlayerEmailsWidget,
    PlayerProfileManagerPopup,
    Notes,
  },
  data() {
    return {
      eventData: {},
      loading: true,
      allWaitingPlayersSelected: false,
      sortWaitingListBy: "createdAt",
      sortWaitingListAscending: false,
      sortCancelledListBy: "statusUpdatedAt",
      sortCancelledListAscending: false,
      sortConfirmedListBy: "createdAt",
      sortConfirmedListAscending: false,
      playerToRemove: null,
      playerToWaitinglist: null,
      playerContactDetails: [],
      addPlayerToEventError: false,
      addPlayerToEventEmail: "",
      addPlayerToEventDivision: null,
      viewedPlayerProfile: null,
    };
  },
  watch: {
    $route: "loadData",
    playerToRemove: function (value) {
      if (value) {
        this.$refs.removeregistration.open();
      } else {
        this.$refs.removeregistration.close();
      }
    },
    playerToWaitinglist: function (value) {
      if (value) {
        this.$refs.waitinglistplayer.open();
      } else {
        this.$refs.waitinglistplayer.close();
      }
    },
  },
  computed: {
    aRoundIsCompleted() {
      return this.eventData.rounds.some((round) => round.status == "COMPLETED");
    },
    waitingPlayersEmails() {
      let string = "";
      this.eventData.registrations.forEach((player, index) => {
        string += player.user.email + ", ";
      });

      return string;
    },
    playerEmailLists() {
      let emailLists = {
        all: "",
        waiting: "",
        confirmed: "",
      };

      this.eventData.registrations.forEach((player, index) => {
        if (player.status == "ACCEPTED") {
          emailLists.confirmed += player.user.email + ", ";
        }
        if (player.status == "PENDING") {
          emailLists.waiting += player.user.email + ", ";
        }
        emailLists.all += player.user.email + ", ";
      });
      return emailLists;
    },
    isEventReallyStarted() {
      let started = false;

      this.eventData.rounds.forEach((round) => {
        round.pools.forEach((pool) => {
          if (pool.status == "OPEN" || pool.status == "COMPLETED") {
            started = true;
          }
        });
      });
      return started;
    },
    isMobile() {
      if (this.$store.getters.deviceWidth < 992) {
        return true;
      }
      return false;
    },
    enoughSpaceToConfirmSelectedPlayers() {
      if (
        this.selectedPlayers.length + this.confirmedPlayers.length <=
        this.eventData.maxSpots
      )
        return true;
      return false;
    },
    stripeManagedTour() {
      if (this.eventData.tour.paymentType == "MANAGED") return true;
      return false;
    },
    wildCardsLeft() {
      if (
        this.eventData.maxWildcards -
          this.eventData.players.filter((player) => player.isWildcard).length >=
        0
      ) {
        return (
          this.eventData.maxWildcards -
          this.eventData.players.filter((player) => player.isWildcard).length
        );
      }
      return 0;
    },
    selectedPlayers() {
      return this.eventData.registrations.filter(
        (registration) => registration.selected
      );
    },
    confirmedPlayers() {
      if (this.sortConfirmedListBy == "onSite") {
        return this.sortByOnSite(
          this.eventData.players,
          this.sortConfirmedListAscending
        );
      } else if (this.sortConfirmedListBy == "firstName") {
        return this.sortByName(
          this.eventData.players,
          this.sortConfirmedListAscending
        );
      } else if (this.sortConfirmedListBy == "pdgaRating") {
        return this.sortByRating(
          this.eventData.players,
          this.sortConfirmedListAscending
        );
      } else if (this.sortConfirmedListBy == "pdgaNumber") {
        return this.sortByPdga(
          this.eventData.players,
          this.sortConfirmedListAscending
        );
      } else if (this.sortConfirmedListBy == "payment") {
        return this.sortByPayment(
          this.eventData.players,
          this.sortConfirmedListAscending
        );
      } else if (this.sortConfirmedListBy == "createdAt") {
        return this.sortPlayerByRegistration(
          this.eventData.players,
          this.sortConfirmedListAscending
        );
      } else if (this.sortConfirmedListBy == "isWildcard") {
        return this.sortByIsWildcard(
          this.eventData.players,
          this.sortConfirmedListAscending
        );
      }
      return null;
    },
    canceledRegistrations() {
      if (this.sortCancelledListBy == "firstName") {
        return this.sortByName(
          this.eventData.registrations.filter(
            (registration) => registration.status == "USER_CANCELLED"
          ),
          this.sortCancelledListAscending
        );
      } else if (this.sortCancelledListBy == "pdgaRating") {
        return this.sortByRating(
          this.eventData.registrations.filter(
            (registration) => registration.status == "USER_CANCELLED"
          ),
          this.sortCancelledListAscending
        );
      } else if (this.sortCancelledListBy == "pdgaNumber") {
        return this.sortByPdga(
          this.eventData.registrations.filter(
            (registration) => registration.status == "USER_CANCELLED"
          ),
          this.sortCancelledListAscending
        );
      } else if (this.sortCancelledListBy == "payment") {
        return this.sortByPayment(
          this.eventData.registrations.filter(
            (registration) => registration.status == "USER_CANCELLED"
          ),
          this.sortCancelledListAscending
        );
      } else if (this.sortCancelledListBy == "statusUpdatedAt") {
        return this.sortByCanceled(
          this.eventData.registrations.filter(
            (registration) => registration.status == "USER_CANCELLED"
          ),
          this.sortCancelledListAscending
        );
      }
    },
    waitingList() {
      if (this.sortWaitingListBy == "firstName") {
        return this.sortByName(
          this.eventData.registrations.filter(
            (registration) => registration.status == "PENDING"
          ),
          this.sortWaitingListAscending
        );
      } else if (this.sortWaitingListBy == "division") {
        return this.sortByDivision(
          this.eventData.registrations.filter(
            (registration) => registration.status == "PENDING"
          ),
          this.sortWaitingListAscending
        );
      } else if (this.sortWaitingListBy == "pdgaRating") {
        return this.sortByRating(
          this.eventData.registrations.filter(
            (registration) => registration.status == "PENDING"
          ),
          this.sortWaitingListAscending
        );
      } else if (this.sortWaitingListBy == "pdgaNumber") {
        return this.sortByPdga(
          this.eventData.registrations.filter(
            (registration) => registration.status == "PENDING"
          ),
          this.sortWaitingListAscending
        );
      } else if (this.sortWaitingListBy == "payment") {
        return this.sortByPayment(
          this.eventData.registrations.filter(
            (registration) => registration.status == "PENDING"
          ),
          this.sortWaitingListAscending
        );
      } else if (this.sortWaitingListBy == "createdAt") {
        return this.sortByRegistration(
          this.eventData.registrations.filter(
            (registration) => registration.status == "PENDING"
          ),
          this.sortWaitingListAscending
        );
      }
    },
  },
  methods: {
    openRegistrationNote(player) {
      console.log(player);
    },
    playersContactDetails(player) {
      return this.playerContactDetails.find(
        (details) => player.user.id == details.userId
      );
    },
    async openPlayerProfile(player) {
      if (this.playerContactDetails.length == 0) {
        await this.loadContactDetails();
      }

      this.viewedPlayerProfile = player;
    },
    emailAndDivisionValid(email, division) {
      return this.validEmail(email) && division != null;
    },
    validEmail(email) {
      return validator.isEmail(email);
    },
    verifyaddUserToEvent() {
      validator.isEmail(this.addPlayerToEventEmail)
        ? this.addUserToEvent()
        : (this.addPlayerToEventError = true);
    },
    async addUserToEvent() {
      const divisionId = this.addPlayerToEventDivision;
      const eventId = this.eventData.id;
      const userEmail = this.addPlayerToEventEmail;
      let userId = null;

      try {
        const userSearchResult = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
              {
                users(search:"${userEmail}"){
                  id
                }
              }
            `,
          },
        });

        if (userSearchResult.data.data.users.length == 0) {
          throw "NOT_FOUND";
        }

        userId = userSearchResult.data.data.users[0].id;

        const addUserToEvent = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
              mutation{
                AddUserToEvent(divisionId:"${divisionId}",eventId:"${eventId}",userId:"${userId}",asRole:TOUR_ADMIN){
                  id
                }
              }
            `,
          },
        });

        if (
          addUserToEvent.data.errors &&
          addUserToEvent.data.errors[0].errorCode == "USER_ALREADY_A_PLAYER"
        ) {
          throw "ALREADY_EXISTS";
        }

        this.$store.dispatch("showMessage", {
          show: true,
          message: "Player added",
          type: "success",
        });

        this.addPlayerToEventEmail = "";
        this.addPlayerToEventDivision = null;

        this.$store.dispatch(
          "loadManagedEventData",
          this.$router.currentRoute.params.eventId
        );
        await this.loadData();

        await this.loadContactDetails();
      } catch (err) {
        if (err == "NOT_FOUND") {
          this.$store.dispatch("showMessage", {
            show: true,
            message: "User not found",
            type: "error",
          });
        }
        if (err == "ALREADY_EXISTS") {
          this.$store.dispatch("showMessage", {
            show: true,
            message: "User already in event",
            type: "error",
          });
        }
      }
    },
    async loadContactDetails() {
      try {
        const contactDetails = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
          {
            event(eventId:"${this.$router.currentRoute.params.eventId}"){
              contactList{
                userId
                firstName
                lastname
                club
                pdgaNumber
                pdgaRating
                phone
                email
              }
            }
          }
          `,
          },
        });
        this.playerContactDetails = contactDetails.data.data.event.contactList;
      } catch (err) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Error loading details",
          type: "error",
        });
      }
    },
    downloadRegistrations() {
      this.$axios({
        method: "get",
        url:
          process.env.VUE_APP_ROOT_EXPORT +
          `events/${this.$router.currentRoute.params.eventId}/export-registrations`,
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        responseType: "blob",
      })
        .then((result) => {
          const url = window.URL.createObjectURL(new Blob([result.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${this.eventData.name}-Registrations.csv`
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {});
    },
    confirmedPlayersActionMenuToUse(player) {
      if (this.eventData.tour.registrationMethod == "GROUPS") {
        return GroupSignupConfirmedPlayersActionMenu(
          this.eventData,
          player,
          this.wildCardsLeft,
          this.isEventReallyStarted
        );
      } else {
        return ListSignupConfirmedPlayersActionMenu(
          this.eventData,
          player,
          this.wildCardsLeft,
          this.isEventReallyStarted
        );
      }
    },
    waitingListActionMenuToUse(player) {
      if (this.eventData.tour.registrationMethod == "GROUPS") {
        return GroupSignupWaitingListActionMenu(
          this.eventData,
          player,
          this.isEventReallyStarted
        );
      } else {
        return ListSignupWaitingListActionMenu(
          this.eventData,
          player,
          this.wildCardsLeft,
          this.isEventReallyStarted
        );
      }
    },
    cancelledRegistrationsActionMenuToUse(player) {
      if (this.eventData.tour.registrationMethod == "GROUPS") {
        return CancelledRegistrationsActionMenu(
          this.eventData,
          player,
          this.isEventReallyStarted
        );
      } else {
        return CancelledRegistrationsActionMenu(
          this.eventData,
          player,
          this.isEventReallyStarted
        );
      }
    },
    changeRegistrationDivision(data) {
      this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
          mutation UpdateRegistration($UpdateRegistration: UpdateRegistrationInput!){
            UpdateRegistration(registrationId:"${data.value.playerId}", input:$UpdateRegistration, asRole:TOUR_ADMIN){
              id
            }
          }
          `,
          variables: {
            UpdateRegistration: {
              divisionId: data.value.divisionId,
            },
          },
        },
      })
        .then(() => {
          this.loadData();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    changePlayerDivision(data) {
      this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
          mutation UpdatePlayer($UpdatePlayer: UpdatePlayerInput!){
            UpdatePlayer(playerId:"${data.value.playerId}", input:$UpdatePlayer, asRole:TOUR_ADMIN){
              id
            }
          }
          `,
          variables: {
            UpdatePlayer: {
              divisionId: data.value.divisionId,
            },
          },
        },
      })
        .then(() => {
          this.loadData();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    acceptAndPaidAllPlayers() {
      if (this.selectedPlayers.length > 0) {
        var mutationQuery = "";

        for (var i = 0; i < this.selectedPlayers.length; i++) {
          mutationQuery += `userA${i}: UpdateRegistrationStatus(registrationId:"${this.selectedPlayers[i].id}",status:ACCEPTED){id}
            userp${i}:UpdateUserPayment(eventId:"${this.$router.currentRoute.params.eventId}",userId:"${this.selectedPlayers[i].user.id}",hasPaid:true) `;
        }

        this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
            mutation{
              ${mutationQuery}
            }
            `,
          },
        })
          .then(() => {
            this.$store.dispatch(
              "loadManagedEventData",
              this.$router.currentRoute.params.eventId
            );
            this.loadData();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    acceptAllPlayers() {
      if (this.selectedPlayers.length > 0) {
        var mutationQuery = "";

        for (var i = 0; i < this.selectedPlayers.length; i++) {
          mutationQuery += `user${i}: UpdateRegistrationStatus(registrationId:"${this.selectedPlayers[i].id}",status:ACCEPTED){id} `;
        }

        this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
            mutation{
              ${mutationQuery}
            }
            `,
          },
        })
          .then(() => {
            this.$store.dispatch(
              "loadManagedEventData",
              this.$router.currentRoute.params.eventId
            );
            this.loadData();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    markAllAsCancelled() {
      if (this.selectedPlayers.length > 0) {
        var mutationQuery = "";

        for (var i = 0; i < this.selectedPlayers.length; i++) {
          mutationQuery += `user${i}:UpdateRegistrationStatus(registrationId:"${this.selectedPlayers[i].id}",status:USER_CANCELLED){player{id}}`;
        }

        this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
            mutation{
                ${mutationQuery}
              }
            `,
          },
        })
          .then(() => {
            this.$store.dispatch(
              "loadManagedEventData",
              this.$router.currentRoute.params.eventId
            );
            this.loadData();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    markAllAsPaid() {
      if (this.selectedPlayers.length > 0) {
        var mutationQuery = "";

        for (var i = 0; i < this.selectedPlayers.length; i++) {
          mutationQuery += `user${i}:UpdateUserPayment(eventId:"${this.$router.currentRoute.params.eventId}",userId:"${this.selectedPlayers[i].user.id}",hasPaid:true) `;
        }

        this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
            mutation{
                ${mutationQuery}
              }
            `,
          },
        })
          .then(() => {
            this.loadData();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    checkIn(player) {
      this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
          mutation UpdatePlayer($UpdatePlayer: UpdatePlayerInput!){
            UpdatePlayer(playerId:"${player.id}", input:$UpdatePlayer, asRole:TOUR_ADMIN){
              id
            }
          }
          `,
          variables: {
            UpdatePlayer: {
              onSite: !player.onSite,
            },
          },
        },
      })
        .then(() => {
          this.loadData();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async removePlayer(selectedPlayer) {
      const registrationId = this.eventData.registrations.find(
        (player) => player.user.id == selectedPlayer.user.id
      );

      try {
        await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
              mutation{
                UpdateRegistrationStatus(registrationId:"${registrationId.id}",status:USER_CANCELLED){
                  player{
                    id
                  }
                }
              }
            `,
          },
        });
      } finally {
        this.playerToRemove = null;
        this.$store.dispatch(
          "loadManagedEventData",
          this.$router.currentRoute.params.eventId
        );
        this.loadData();
      }
    },
    togglePayment(userId, bool) {
      this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
          mutation{
              UpdateUserPayment(eventId:"${this.$router.currentRoute.params.eventId}",userId:"${userId}",hasPaid:${bool})
            }
          `,
        },
      })
        .then(() => {
          this.loadData();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    acceptAndWildcardPlayer(selectedPlayer) {
      let registrationId = this.eventData.registrations.filter(
        (player) => player.user.id == selectedPlayer.user.id
      )[0];

      this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
          mutation{
            UpdateRegistrationStatus(registrationId:"${registrationId.id}",status:ACCEPTED){
              player{
                id
              }
            }
          }
          `,
        },
      })
        .then((result) => {
          this.wildcardPlayer(
            result.data.data.UpdateRegistrationStatus.player,
            true
          );
        })
        .catch((error) => {
          console.log(error);
        });
    },
    wildcardPlayer(player, bool) {
      this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
          mutation UpdatePlayer($UpdatePlayer: UpdatePlayerInput!){
            UpdatePlayer(playerId:"${player.id}",input: $UpdatePlayer, asRole:TOUR_ADMIN){
              id
              isWildcard
            }
          }
          `,
          variables: {
            UpdatePlayer: { isWildcard: bool },
          },
        },
      })
        .then(() => {
          this.loadData();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    movePlayerToWaitinglist(selectedPlayer) {
      const registrationId = this.eventData.registrations.find(
        (player) => player.user.id == selectedPlayer.user.id
      );

      this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
          mutation{
            UpdateRegistrationStatus(registrationId:"${registrationId.id}",status:PENDING){
              player{
                id
              }
            }
          }
          `,
        },
      })
        .then(() => {
          this.playerToWaitinglist = null;
          this.$store.dispatch(
            "loadManagedEventData",
            this.$router.currentRoute.params.eventId
          );
          this.loadData();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    wildcardPlayerAndPayment(player, bool) {
      this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
          mutation{
            UpdateRegistrationStatus(registrationId:"${player.id}",status:ACCEPTED){
              player{
                id
              }
            }
            UpdateUserPayment(eventId:"${this.$router.currentRoute.params.eventId}",userId:"${player.user.id}",hasPaid:${bool})
          }
          `,
        },
      })
        .then((result) => {
          this.wildcardPlayer(
            result.data.data.UpdateRegistrationStatus.player,
            true
          );
        })
        .catch((error) => {
          console.log(error);
        });
    },
    acceptPlayerAndPayment(player, bool) {
      this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
          mutation{
            UpdateRegistrationStatus(registrationId:"${player.id}",status:ACCEPTED){
              player{
                id
              }
            }
            UpdateUserPayment(eventId:"${this.$router.currentRoute.params.eventId}",userId:"${player.user.id}",hasPaid:${bool})
          }
          `,
        },
      })
        .then(() => {
          this.$store.dispatch(
            "loadManagedEventData",
            this.$router.currentRoute.params.eventId
          );
          this.loadData();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    acceptPlayer(player) {
      this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
          mutation{
            UpdateRegistrationStatus(registrationId:"${player.id}",status:ACCEPTED){
              player{
                id
              }
            }
          }
          `,
        },
      })
        .then(() => {
          this.$store.dispatch(
            "loadManagedEventData",
            this.$router.currentRoute.params.eventId
          );
          this.loadData();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    sortPlayerByRegistration(arrayToSort, ascending) {
      if (!ascending) {
        return arrayToSort.sort(
          (a, b) =>
            moment(a.registration.createdAt) - moment(b.registration.createdAt)
        );
      } else {
        return arrayToSort.sort(
          (a, b) =>
            moment(b.registration.createdAt) - moment(a.registration.createdAt)
        );
      }
    },
    sortByRegistration(arrayToSort, ascending) {
      if (!ascending) {
        return arrayToSort.sort(
          (a, b) => moment(a.createdAt) - moment(b.createdAt)
        );
      } else {
        return arrayToSort.sort(
          (a, b) => moment(b.createdAt) - moment(a.createdAt)
        );
      }
    },
    sortByCanceled(arrayToSort, ascending) {
      if (!ascending) {
        return arrayToSort.sort(
          (a, b) => moment(a.statusUpdatedAt) - moment(b.statusUpdatedAt)
        );
      } else {
        return arrayToSort.sort(
          (a, b) => moment(b.statusUpdatedAt) - moment(a.statusUpdatedAt)
        );
      }
    },
    sortByIsWildcard(arrayToSort, ascending) {
      if (!ascending) {
        return arrayToSort.sort((a, b) => a.isWildcard - b.isWildcard);
      } else {
        return arrayToSort.sort((a, b) => b.isWildcard - a.isWildcard);
      }
    },
    sortByOnSite(arrayToSort, ascending) {
      if (!ascending) {
        return arrayToSort.sort((a, b) => a.onSite - b.onSite);
      } else {
        return arrayToSort.sort((a, b) => b.onSite - a.onSite);
      }
    },
    sortByPayment(arrayToSort, ascending) {
      if (!ascending) {
        return arrayToSort.sort((a, b) => a.hasPaid - b.hasPaid);
      } else {
        return arrayToSort.sort((a, b) => b.hasPaid - a.hasPaid);
      }
    },
    sortByDivision(arrayToSort, ascending) {
      if (!ascending) {
        return arrayToSort.sort((a, b) =>
          a.division.type.localeCompare(b.division.type)
        );
      } else {
        return arrayToSort.sort((a, b) =>
          b.division.type.localeCompare(a.division.type)
        );
      }
    },
    sortByName(arrayToSort, ascending) {
      if (!ascending) {
        return arrayToSort.sort((a, b) =>
          a.user.firstName.localeCompare(b.user.firstName)
        );
      } else {
        return arrayToSort.sort((a, b) =>
          b.user.firstName.localeCompare(a.user.firstName)
        );
      }
    },
    sortByPdga(arrayToSort, sortAscending) {
      var alphabetically = function (ascending) {
        return function (a, b) {
          if (a.user.profile.pdgaNumber === b.user.profile.pdgaNumber) {
            return 0;
          } else if (ascending) {
            return a.user.profile.pdgaNumber < b.user.profile.pdgaNumber
              ? -1
              : 1;
          } else if (!ascending) {
            return a.user.profile.pdgaNumber < b.user.profile.pdgaNumber
              ? 1
              : -1;
          }
        };
      };

      return arrayToSort.sort(alphabetically(sortAscending));
    },
    sortByRating(arrayToSort, sortAscending) {
      var alphabetically = function (ascending) {
        return function (a, b) {
          if (a.user.profile.pdgaRating === b.user.profile.pdgaRating) {
            return 0;
          } else if (ascending) {
            return a.user.profile.pdgaRating < b.user.profile.pdgaRating
              ? -1
              : 1;
          } else if (!ascending) {
            return a.user.profile.pdgaRating < b.user.profile.pdgaRating
              ? 1
              : -1;
          }
        };
      };
      return arrayToSort.sort(alphabetically(sortAscending));
    },
    selectAllInWaitinglist(bool) {
      this.waitingList.forEach((player) => {
        player.selected = bool;
      });
    },
    selectAllInDivision(bool, division) {
      let allInDivisionList = this.waitingList.filter(
        (player) => player.division.id == division.id
      );

      for (var i = 0; i < allInDivisionList.length; i++) {
        allInDivisionList[i].selected = bool;
      }
    },
    detailedRegTime(timeStamp) {
      return moment(timeStamp).format("D MMM HH:mm:ss");
    },
    regTime(timeStamp) {
      if (this.$store.getters.deviceWidth > 660) {
        return moment(timeStamp).format("D MMM HH:mm");
      }
      return moment(timeStamp).format("D MMM");
    },
    playersInDivision(division) {
      return this.confirmedPlayers.filter(
        (player) => player.division.id == division.id
      );
    },
    playersInDivisionCount(division) {
      return this.confirmedPlayers.filter(
        (player) => player.division.id == division.id
      ).length;
    },
    playersInWaitingListDivision(division) {
      return this.waitingList.filter(
        (player) => player.division.id == division.id
      );
    },
    playersInWaitingListDivisionCount(division) {
      return this.waitingList.filter(
        (player) => player.division.id == division.id
      ).length;
    },
    cancelledRegistrationDivision(division) {
      return this.canceledRegistrations.filter(
        (player) => player.division.id == division.id
      );
    },
    canceledRegistrationsDivisionCount(division) {
      return this.canceledRegistrations.filter(
        (player) => player.division.id == division.id
      ).length;
    },
    async loadData() {
      await this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
          {
            event(eventId:"${this.$router.currentRoute.params.eventId}"){
              id
              name
              maxSpots
              maxWildcards
              status
              waitinglistType{
                type
                name
              }
              rounds{
                status
                pools{
                  status
                }
              }
              tour{
                paymentType
                registrationMethod
                automaticRefunds
                divisions{
                  position
                  id
                  type
                  name
                  maxSpots
                  reservedSpots
                }
              }
              registrations{
                id
                status
                createdAt
                statusUpdatedAt
                hasPaid
                message
                division{
                  id
                  name
                  type
                }
                player{
                  onSite
                  isWildcard
                  id
                }
                user{
                  id
                  firstName
                  lastName
                  email
                  profile{
                    id
                    club
                    pdgaNumber
                    pdgaRating
                  }
                }
              }
              players{
                id
                onSite
                divisionId
                isWildcard
                hasPaid
                division{
                  id
                  type
                  name
                }
                user{
                  id
                  firstName
                  lastName
                  profile{
                    id
                    club
                    pdgaNumber
                    pdgaRating
                  }
                }
              }
            }
          }
          `,
        },
      })
        .then((result) => {
          this.allWaitingPlayersSelected = false;
          for (
            var i = 0;
            i < result.data.data.event.registrations.length;
            i++
          ) {
            result.data.data.event.registrations[i].selected = false;
          }
          for (
            var j = 0;
            j < result.data.data.event.tour.divisions.length;
            j++
          ) {
            result.data.data.event.tour.divisions[j].selectAll = false;
          }

          result.data.data.event.players.map((player) => {
            player.registration = result.data.data.event.registrations.filter(
              (registration) => {
                return registration.user.id == player.user.id;
              }
            )[0];
          });

          this.eventData = result.data.data.event;
          this.loading = false;
        })
        .catch(() => {});
    },
  },
  mounted() {
    this.loadData();
  },
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.export-button {
  height: 48px;
  border: none;
  background: $mist;
  @include Gilroy-Bold;
  transition: all ease 0.3s;
  margin-top: 32px;
  &:hover {
    background: $fog;
  }
}
.event-players {
  .section {
    background-color: white;
    margin-bottom: 20px;
    padding: 20px 0px 0px 0;

    h5 {
      padding-left: 18px;
      font-size: 16px;
      @include Gilroy-Bold;
      margin-bottom: 16px;
      display: flex;
      align-items: center;

      svg {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }
      span {
        margin-left: 4px;
        @include Gilroy-Regular;
      }
    }

    &.summary {
      padding: 20px 0;
    }

    .content {
      padding: 0 18px;
      display: flex;
      flex-direction: column;

      &.copy-section {
        padding-bottom: 24px;
      }
      p {
        font-size: 14px;
        margin: 0;
      }

      .copy-container {
        display: flex;
        flex-direction: column;
        .context-menu {
          margin-left: auto;
          margin-top: 32px;
        }
        p {
          margin: 0;
          font-size: 14px;
        }
      }

      .copy-buttons {
        button {
          margin-bottom: 15px;
        }
      }

      &:not(:first-of-type) {
        padding-top: 24px;
        border-top: 1px solid $sleet;
      }
    }

    .add-input {
      position: relative;
      margin-top: 12px;
      border: none;
      width: 100%;
      height: 48px;
      border: 1px solid $blizzard;
      border-radius: 6px;
      padding: 0 12px;
      transition: border ease 0.3s;

      ::placeholder {
        color: $midnight;
        font-size: 16px;
      }

      &:focus {
        outline: none;
      }

      &.error {
        border: 1px solid $dusk;
      }
    }

    .division-select {
      margin: 18px 0 0 0;
      @include Gilroy-Regular;
    }
    .add-player-action {
      margin-top: 18px;
    }

    .add-tip {
      display: flex;
      align-items: flex-start;
      margin: 24px 0 32px 0;
      p {
        margin: 0;
        line-height: 1.4em;
        margin-left: 8px;
      }
    }
  }
}
.summary-container {
  display: flex;
  justify-content: space-between;

  .summary-item {
    display: flex;
    align-items: center;
    width: 20%;
    justify-content: center;

    p {
      margin: 0;
      font-size: 14px;
      @include Gilroy-Bold;
      color: $blizzard;
    }

    span {
      display: none;
    }

    svg {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
  }
}

.confirmed-players {
  margin-top: 46px;
  font-size: 14px;
  @include Gilroy-Bold;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  img {
    height: 16px;
    margin-right: 7px;
  }

  .total-count {
    margin-left: auto;
    border-bottom: 2px solid $lightavocado;
    display: inline-flex;
    height: 20px;
    margin-right: 10px;
  }
}

.waiting-players {
  font-size: 14px;
  @include Gilroy-Bold;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  img {
    height: 16px;
    margin-right: 7px;
  }

  .total-count {
    margin-left: auto;
    border-bottom: 2px solid $lightpeach;
    display: inline-flex;
    height: 20px;
    margin-right: 10px;
  }
}
.cancelled-players {
  font-size: 14px;
  @include Gilroy-Bold;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  img {
    height: 16px;
    margin-right: 7px;
  }

  .total-count {
    margin-left: auto;
    border-bottom: 2px solid $watermelonhollowhover;
    display: inline-flex;
    height: 20px;
    margin-right: 10px;
  }
}

.division-container {
  margin-top: 20px;
  margin-bottom: 46px;

  .header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 18px;
    height: 32px;

    p {
      margin: 0;
      font-size: 14px;
      display: flex;
      justify-content: flex-start;
      width: 100%;
      b {
        margin-right: 4px;
      }
    }

    &.confirmed {
      background-color: #e5f8f8;
    }
    &.waiting {
      background-color: #fff0ec;
    }

    &.cancelled {
      background-color: #ffe5ef;
    }
  }
}

table {
  table-layout: fixed;
  width: 100%;

  thead {
    text-align: center;

    &.confirmed {
      .active {
        padding-bottom: 1px;
        border-bottom: 2px solid $ocean;
      }
    }

    &.waiting {
      .active {
        padding-bottom: 1px;
        border-bottom: 2px solid $sunset;
      }
    }

    &.cancelled {
      .active {
        padding-bottom: 1px;
        border-bottom: 2px solid $dusk;
      }
    }
    th {
      color: $midnight;
      @include Gilroy-Bold;
      font-size: 10px;
    }

    .check-box {
      width: 28px;
      padding-right: 12px;
      text-align: left;

      .checkmark {
        border: 1px solid $elephant;
      }
    }
    .position {
      width: 10%;
    }

    .player {
      width: auto;
      text-align: left;
    }
    .division {
      display: none;
    }
    .on-site {
      width: 12%;

      span:after {
        content: "CI";
      }
    }

    .pdga-rating {
      width: 15%;
      display: none;
      span:after {
        content: "RAT";
      }
    }

    .payment {
      width: 10%;
    }
    .refund {
      width: 15%;
    }

    .action-menu {
      width: 15%;

      span:after {
        content: "ACT";
      }
    }
  }

  tbody {
    text-align: center;

    a {
      &:hover {
        text-decoration: underline;
      }
    }
    .pdga-rating {
      display: none;
      @include Gilroy-Regular;
    }
    .division {
      display: none;
    }
    .check-box {
      text-align: left;
      padding-right: 12px;
    }
    .on-site {
      vertical-align: middle;
    }
    .position {
      @include Gilroy-Regular;
      text-align: left;
      padding-left: 18px;
    }

    .player {
      text-align: left;

      .registration-note {
        margin-left: 8px;
        font-size: 8px;
        @include Gilroy-LightItalic;
        cursor: pointer;

        p {
          word-wrap: break-word;
        }
      }

      .player-info {
        display: flex;
        align-items: center;
        cursor: pointer;

        span {
          display: flex;
          margin-right: 10px;
        }

        svg {
          height: 16px;
          width: 16px;
        }

        p {
          font-size: 14px;
          margin: 0;
          @include Gilroy-Bold;
        }

        .notes {
          margin-left: 6px;
          width: 16px;
          height: 15px;
        }
      }
    }
    .reg-time {
      span {
        display: none;
      }
    }
  }
}

.all-actions-container {
  background-color: $snow;
  padding: 25px 30px;
  flex-wrap: wrap;
  border: 1px solid $ocean;
  border-radius: 4px;

  span {
    display: flex;
    flex-direction: column;

    .button-container {
      margin: 12px 0;
    }
  }

  p {
    @include Gilroy-Bold;
    margin-bottom: 15px;

    &.full-row-of-text {
      width: 100%;
      display: block;
      @include Gilroy-Regular;
      margin-top: 26px;
      color: $watermelon;
      font-size: 12px;
    }
  }

  i {
    @include Gilroy-Regular;
  }

  span {
    display: flex;
    justify-content: space-between;
  }
}

.reserved-spots {
  font-size: 12px;
  margin-left: auto;
}

@media (min-width: 480px) and (orientation: landscape) {
  table {
    table-layout: fixed;
    width: 100%;

    thead {
      text-align: center;
      @include Gilroy-Bold;

      span {
        cursor: pointer;
        -webkit-user-select: none; /* Safari */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* IE10+/Edge */
        user-select: none; /* Standard */
      }

      &.confirmed {
        .active {
          padding-bottom: 1px;
          border-bottom: 2px solid $ocean;
        }
      }

      &.waiting {
        .active {
          padding-bottom: 1px;
          border-bottom: 2px solid $sunset;
        }
      }

      &.cancelled {
        .active {
          padding-bottom: 1px;
          border-bottom: 2px solid $dusk;
        }
      }

      .check-box {
        .checkmark {
          border: 1px solid $elephant;
        }
      }
      .position {
        width: 6%;
      }
      .check-box {
        width: 28px;
        padding-right: 12px;
        text-align: left;
      }

      .player {
        width: auto;
        text-align: left;
      }
      .division {
        width: 18%;
        text-align: left;
        display: table-cell;
      }

      .on-site {
        width: 6%;

        span:after {
          content: "chk-in";
        }
      }
      .pdga-number {
        width: 10%;
      }

      .pdga-rating {
        width: 10%;
        display: table-cell;

        span:after {
          content: "RATING";
        }
      }

      .payment {
        width: 6%;
      }

      .wildcard {
        width: 6%;
        span:after {
          content: "WC";
        }
      }

      .reg-time {
        width: 13%;
      }

      .action-menu {
        width: 10%;

        span {
          cursor: default;
        }

        span:after {
          content: "ACTION";
        }
      }
    }

    tbody {
      text-align: center;

      .on-site {
        vertical-align: middle;
      }
      .position {
        @include Gilroy-Regular;
      }

      .check-box {
        padding-right: 12px;
        text-align: left;
      }

      .player {
        text-align: left;
      }
      .division {
        text-align: left;
        @include Gilroy-Regular;
        display: table-cell;
      }
      .pdga-number,
      .pdga-rating {
        @include Gilroy-Regular;
        display: table-cell;
      }
      .reg-time {
        @include Gilroy-Regular;
      }

      .wildcard {
        img {
          height: 18px;
        }
      }
    }
  }
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 1200px) {
  .export-button {
    margin-top: 0;
  }
  .event-players {
    .section {
      padding: 30px 30px 40px 30px;
      background-color: white;
      border-radius: 10px;
      box-shadow: 0 0 3px $sleet;
      margin-bottom: 32px;
      width: 100%;

      .content {
        flex-direction: row;

        &.add-players {
          flex-wrap: wrap;

          p {
            width: 100%;
          }

          .add-section {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            margin-top: 24px;
            .add-input {
              margin: 0;
              width: 350px;

              &::placeholder {
                color: $blizzard;
              }
            }
            .division-select {
              width: 350px;
              margin: 0 0 0 24px;
            }
            .add-player-action {
              margin: 0 0 0 24px;
            }
            .add-tip {
              width: 100%;
              margin: 24px 0 0 0;
            }
          }
        }

        &.copy-section {
          align-items: center;
          p {
            width: 80%;
            font-size: 16px;
          }
          .copy-container {
            width: 100%;
            flex-direction: row;
            align-items: center;
            .context-menu {
              margin-top: 0;
            }

            p {
              font-size: 16px;
            }
          }
        }
        p {
          width: 50%;
          margin-bottom: 0;
        }

        .copy-buttons {
          button {
            margin-top: 20px;
            margin-right: 24px;
            width: auto;
          }
        }
      }

      .division-container:last-of-type {
        margin: 0;
      }
      h5 {
        padding-left: 0;
        font-size: 20px;
        @include Gilroy-Bold;
        margin-bottom: 32px;

        &.small-margin {
          margin-bottom: 24px;
        }
        span {
          @include Gilroy-Regular;
        }
      }

      h6 {
        font-size: 16px;
        width: 100%;
      }

      .content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
      }
      &.summary {
        border-radius: 10px;
        padding: 32px 30px;
      }
    }
  }
  .summary-container {
    display: flex;
    justify-content: space-between;
    border-radius: 10px;

    .summary-item {
      display: flex;
      align-items: center;
      width: auto;
      flex-direction: row;

      svg {
        margin-right: 10px;
      }
      p {
        @include Gilroy-Bold;
        color: $midnight;
        font-size: 18px;
      }

      span {
        display: inline;
        @include Gilroy-Regular;
      }
    }
  }

  .confirmed-players {
    margin-top: 80px;
    font-size: 26px;
    @include Gilroy-Medium;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    img {
      height: 32px;
      margin-right: 15px;
    }

    .total-count {
      margin-left: auto;
      border-bottom: 2px solid $lightavocado;
      display: inline-flex;
      height: 34px;
      margin-right: 15px;
    }
  }

  .waiting-players {
    font-size: 26px;
    @include Gilroy-Medium;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    img {
      height: 32px;
      margin-right: 15px;
    }

    .total-count {
      margin-left: auto;
      border-bottom: 2px solid $lightpeach;
      display: inline-flex;
      height: 34px;
      margin-right: 15px;
    }
  }
  .cancelled-players {
    font-size: 26px;
    @include Gilroy-Medium;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    img {
      height: 32px;
      margin-right: 15px;
    }

    .total-count {
      margin-left: auto;
      border-bottom: 2px solid $watermelonhollowhover;
      display: inline-flex;
      height: 34px;
      margin-right: 15px;
    }
  }

  .division-container {
    margin-bottom: 80px;

    .header {
      height: 40px;
      border-radius: 10px;
      padding: 0 15px;
      font-size: 14px;
      p {
        font-size: 16px;
      }
    }

    table {
      background-color: white;

      thead {
        th {
          @include Gilroy-Bold;
          color: $midnight;
          font-size: 12px;
        }
        .position {
          width: 5%;
        }
        .on-site {
          width: 10%;

          span:after {
            content: "Check-In";
          }
        }

        .check-box {
          width: 40px;
          text-align: left;
          padding-right: 16px;
        }
      }
      tbody {
        td {
          border-bottom: 1px solid $fog;
        }
        .check-box {
          text-align: left;
          padding-right: 16px;
        }
        .player {
          @include Gilroy-Bold;
          .player-info {
            p {
              font-size: 16px;
            }
            span {
              margin-right: 12px;
            }
            svg {
              margin-left: 8px;
            }
          }
        }
      }
    }
  }

  .all-actions-container {
    background-color: $mist;
    padding: 25px 30px;
    display: flex;
    align-items: center;

    p {
      @include Gilroy-Bold;
      margin-bottom: 0;
      margin-right: 15px;
    }

    span {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      .button-container {
        margin-right: 20px;
      }
    }
  }
}
</style>
