<template>
  <div class="context-menu">
    <span
      :title="title"
      class="icon"
      :class="{ active: showMenu }"
      ref="icon"
      @click.stop="openMenu()"
    >
      <p class="icon-text">{{ text }}</p>
    </span>

    <transition name="menufade">
      <div class="menu-container" ref="tooltip" v-if="showMenu">
        <div class="menu-title" v-if="title">
          <p>
            <b>{{ title }}</b>
          </p>
        </div>
        <ul>
          <li
            class="menu-item"
            v-for="item in menuItems"
            :class="{ disabled: item.disabled }"
            @click="copyEmailsToClipboard(item.action)"
          >
            <p>{{ item.title }}</p>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";
export default {
  name: "TjingCopyPlayerEmailsWidget",
  props: ["title", "text", "position", "registrations"],
  data() {
    return {
      showMenu: false,
      popperInstance: null,
      playerContactDetails: [],
      menuItems: [
        {
          title: "All players",
          disabled: false,
          action: "ALL",
        },
        {
          title: "Confirmed players",
          disabled: false,
          action: "CONFIRMED",
        },
        {
          title: "Waiting players",
          disabled: false,
          action: "WAITING",
        },
      ],
    };
  },
  watch: {
    escapePressed() {
      this.closeMenu();
    },
  },
  computed: {
    deviceWidth() {
      return this.$store.getters.deviceWidth;
    },
    escapePressed() {
      return this.$store.getters.escapePressed;
    },
  },
  methods: {
    async loadContactDetails() {
      try {
        const contactDetails = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
          {
            event(eventId:"${this.$router.currentRoute.params.eventId}"){
              contactList{
                userId
                firstName
                lastname
                club
                pdgaNumber
                pdgaRating
                phone
                email
              }
            }
          }
          `,
          },
        });
        this.playerContactDetails = contactDetails.data.data.event.contactList;
      } catch (err) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Error loading details",
          type: "error",
        });
      }
    },
    async copyEmailsToClipboard(scope) {
      const registrations = this.registrations;
      let formattedEmailString = "";

      this.playerContactDetails.forEach((user) => {
        if (scope == "ALL") {
          registrations.some(
            (reg) =>
              (reg.status == "PENDING" || reg.status == "ACCEPTED") &&
              reg.user.id == user.userId
          )
            ? (formattedEmailString += `${user.firstName} ${user.lastname} <${user.email}>, `)
            : "";
        } else if (scope == "CONFIRMED") {
          registrations.some(
            (reg) => reg.status == "ACCEPTED" && reg.user.id == user.userId
          )
            ? (formattedEmailString += `${user.firstName} ${user.lastname} <${user.email}>, `)
            : "";
        } else if (scope == "WAITING") {
          registrations.some(
            (reg) => reg.status == "PENDING" && reg.user.id == user.userId
          )
            ? (formattedEmailString += `${user.firstName} ${user.lastname} <${user.email}>, `)
            : "";
        }
      });

      if (formattedEmailString == "") {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "No players to copy",
          type: "warning",
        });
      } else {
        try {
          await navigator.clipboard.writeText(formattedEmailString);

          this.$store.dispatch("showMessage", {
            show: true,
            message: "Emails copied",
            type: "success",
          });
        } catch (err) {
          console.log(err);
          this.$store.dispatch("showMessage", {
            show: true,
            message: "Unable to copy emails",
            type: "error",
          });
        } finally {
          this.closeMenu();
        }
      }
    },
    async openMenu() {
      if (this.playerContactDetails.length == 0) {
        await this.loadContactDetails();
      }
      document.addEventListener("click", this.clickEvent);

      if (this.showMenu) {
        this.closeMenu();
      } else {
        this.showMenu = true;
        this.$nextTick(() => {
          const button = this.$refs.icon;
          const tooltip = this.$refs.tooltip;

          if (this.deviceWidth > 992)
            this.popperInstance = createPopper(button, tooltip, {
              placement: this.position,
              modifiers: [
                {
                  name: "preventOverflow",
                  options: {
                    mainAxis: true,
                    padding: { right: 0, left: 0 },
                  },
                },
                {
                  name: "offset",
                  options: {
                    offset: [0, 12],
                  },
                },
              ],
            });
        });
      }
    },
    clickEvent(e) {
      //var element = document.getElementById("parent-node");
      var element = this.$refs.icon;
      if (e.target !== element && !element?.contains(e.target)) {
        this.closeMenu();
      }
    },
    closeMenu() {
      this.showMenu = false;

      document.removeEventListener("click", this.clickEvent);

      setTimeout(() => {
        if (this.popperInstance != null) {
          this.popperInstance.destroy();
          this.popperInstance = null;
        }
      }, 300);
    },
  },
  mounted() {},
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
@import "@/styles/Typography";

.menufade-enter-active,
.menufade-leave-active {
  transition: opacity 0.3s;
}
.menufade-enter, .menufade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.context-menu {
  display: inline-block;
  line-height: 1.4em;
  font-size: 14px;
  width: 100%;
  position: relative;

  .icon {
    cursor: pointer;
    display: inline-flex;
    overflow: visible;
    line-height: 1.4em;
    align-items: center;
    height: 48px;
    background: $mist;
    border-radius: 6px;
    padding: 0 12px;
    transition: all ease 0.3s;
    justify-content: center;
    width: 100%;

    &:hover {
      background: $fog;
    }

    &.active {
      background: $fog;
    }
  }

  .icon-text {
    margin: 0;
    color: $midnight;
    @include Gilroy-Bold;
    font-size: 16px;
  }
}

.menu-container {
  position: absolute;
  top: calc(100% + 12px);
  width: 100%;

  &.right {
    right: 0;
  }
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu-container {
  background: white;
  border: 1px solid $sleet;
  border-radius: 6px;

  :last-child {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  :first-child {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  .menu-title {
    border-radius: 6px 6px 0 0;
    border-bottom: 1px solid $sleet;
    padding: 12px 18px;
    user-select: none;
  }

  .menu-item {
    padding: 12px 18px 12px 18px;
    transition: all ease 0.3s;
    user-select: none;
    &:hover {
      background: $cloud;
      cursor: pointer;
    }

    p {
      white-space: nowrap;
    }

    &.disabled {
      color: $blizzard;
      &:hover {
        background: white;
        cursor: default;
      }
    }
  }
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .context-menu {
    width: auto;

    .menu-container {
      width: unset;
    }
    .icon {
      height: 48px;
      padding: 0 20px;
      cursor: pointer;

      .icon-text {
        font-size: 16px;
      }
    }
  }
}
</style>
