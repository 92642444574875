<template>
  <div class="action-menu" v-click-outside="close">
    <div class="action-menu-container" @click="open = !open">
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
        >
          <circle class="a" cx="16" cy="16" r="16" />
          <g transform="translate(-1281 -267)">
            <circle
              class="b"
              cx="3"
              cy="3"
              r="3"
              transform="translate(1284 280)"
            />
            <circle
              class="b"
              cx="3"
              cy="3"
              r="3"
              transform="translate(1294 280)"
            />
            <circle
              class="b"
              cx="3"
              cy="3"
              r="3"
              transform="translate(1304 280)"
            />
          </g>
        </svg>
      </span>
      <transition name="menufade" mode="out-in">
        <div v-show="open" class="items-container">
          <div
            class="item"
            :class="{
              disabled: item.disabled,
              expanded: openSubItem == itemIndex,
            }"
            :style="{ color: item.textColor }"
            v-for="(item, itemIndex) in items"
            @click.stop.prevent="emitter(item, itemIndex)"
            :key="itemIndex"
          >
            <p>{{ item.title }}</p>

            <img
              src="@/assets/img/expand-right-dark.svg"
              alt
              v-if="item.subItems.length > 0"
            />
            <div class="sub-item" v-if="item.subItems.length > 0">
              <div
                class="child"
                v-for="(subItem, subItemIndex) in item.subItems"
                @click="subEmitter(subItem)"
                :key="subItemIndex"
              >
                {{ subItem.title }}
              </div>
            </div>
            <div
              class="confirmation-container"
              v-if="showConfirmation == itemIndex"
            >
              <transition name="slideinleft">
                <div class="yes" @click.stop="emitter(item, itemIndex)">
                  Yes
                </div>
              </transition>
              <transition name="slideinright">
                <div class="no" @click.stop="showConfirmation = -1">No</div>
              </transition>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";

export default {
  name: "ActionMenu",
  props: ["items"],
  data() {
    return {
      open: false,
      showConfirmation: -1,
      openSubItem: null,
    };
  },

  methods: {
    subEmitter(subItem) {
      this.close();
      setTimeout(() => {
        this.$emit(subItem.action, subItem);
      }, 300);
    },
    emitter(item, index) {
      if (item.subItems.length > 0) {
        if (this.openSubItem == index) {
          this.openSubItem = null;
        } else {
          this.openSubItem = index;
        }
      } else if (item.confirmationRequired && this.showConfirmation != index) {
        this.showConfirmation = index;
      } else {
        if (!item.disabled && item.subItems.length == 0) {
          this.close();
          setTimeout(() => {
            this.$emit(item.action);
          }, 300);
        }
      }
    },
    close() {
      this.open = false;
    },
  },
  watch: {
    escapePressed() {
      this.open = false;
    },
  },
  computed: {
    escapePressed() {
      return this.$store.getters.escapePressed;
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/Typography";
.menufade-enter-active,
.menufade-leave-active {
  transition: opacity 0.3s;
}
.menufade-enter, .menufade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.slideinleft-enter-active {
  animation: slide-in-left 0.3s;
}
.slideinleft-leave-active {
  animation: slide-in-left 0.3s reverse;
}
.slideinright-enter-active {
  animation: slide-in-right 0.3s;
}
.slideinright-leave-active {
  animation: slide-in-right 0.3s reverse;
}
@keyframes slide-in-left {
  0% {
    left: -100%;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    right: -100%;
    opacity: 0;
  }
  100% {
    right: 10px;
    opacity: 1;
  }
}
.action-menu {
  position: relative;
  cursor: pointer;
}
.items-container {
  position: absolute;
  top: 42px;
  white-space: nowrap;
  transform: translate(-40%, 0);
  background: white;
  width: auto;
  z-index: 999999;
  border: 1px solid $sleet;
  border-radius: 6px;
  z-index: 1;

  .item {
    display: flex;
    min-width: 180px;
    align-items: flex-start;
    flex-direction: column;
    background-color: white;
    @include Gilroy-Regular;
    position: relative;
    font-size: 14px;
    z-index: 1;
    transition: all ease 0.3s;

    &:hover {
      transition: all ease 0.3s;
      background-color: $mist;
    }

    &:first-of-type {
      border-radius: 4px;
    }
    &:last-of-type {
      border-radius: 4px;
    }

    p {
      padding: 12px 18px 12px 18px;
    }

    &.expanded {
      padding-bottom: 0;
      transition: all ease 0.3s;

      &:hover {
        background: white;
      }

      img {
        transform: rotate(-90deg);
      }
      .sub-item {
        .child {
          max-height: 40px;
          &:hover {
            background-color: $mist;
          }
        }
      }
    }

    .confirmation-container {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      overflow: hidden;
      z-index: 0;

      div {
        width: 51%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;

        &.yes {
          background-color: $grass;
          left: 0;
          color: white;
          transition: all ease 0.3s;
          animation: slide-in-left 0.3s ease;

          &:hover {
            background-color: $avocadohover;
          }
        }

        &.no {
          background-color: $dusk;
          right: 0;
          color: white;
          transition: all ease 0.3s;
          animation: slide-in-right 0.3s ease;

          &:hover {
            background-color: $watermelonhover;
          }
        }
      }
    }

    img {
      position: absolute;
      right: 15px;
      height: 12px;
      top: 14px;
      transform: rotate(90deg);
      transition: all ease 0.3s;
    }

    .sub-item {
      width: 100%;
      background-color: white;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-size: 14px;
      transition: all ease 0.3s;

      .child {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        max-height: 0;
        overflow: hidden;
        transition: all ease 0.3s;
        width: 100%;
        transition: all ease 0.3s;
        justify-content: flex-start;
        padding: 0 0 0 32px;
      }
    }

    &.disabled {
      color: $mouse;
    }
  }
}

.action-menu-container {
  height: 40px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;

  span {
    padding: 3px;
    border-radius: 20px;
    transition: all ease 0.3s;
    &:hover {
      padding: 2px;
      border-radius: 20px;
      background-color: $fog;
    }
    svg {
      width: 70%;
    }
    .a {
      fill: #e7e9ec;
      opacity: 0;
    }
    .b {
      fill: #2d323c;
    }
  }

  .dot {
    width: 4px;
    height: 4px;
    background-color: $midnight;
    margin: 0 1px;
    border-radius: 50%;
  }
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
  .bounce-enter-active {
    animation: bounce-in 0.3s;
  }
  .bounce-leave-active {
    animation: bounce-in 0.3s reverse;
  }

  .action-menu-container {
    span {
      padding: 3px;
      border-radius: 20px;
      transition: all ease 0.3s;
      &:hover {
        padding: 3px;
        border-radius: 20px;
        background-color: $fog;
      }
      svg {
        width: unset;
      }
      .a {
        fill: #e7e9ec;
        opacity: 0;
      }
      .b {
        fill: #2d323c;
      }
    }
  }
}
</style>
