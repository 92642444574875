<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="16"
    viewBox="0 0 17 16"
  >
    <g id="Notes_icon" data-name="Notes icon" transform="translate(0.5 0.5)">
      <path
        id="Union_5"
        data-name="Union 5"
        d="M11.082,12H5.333a2,2,0,0,1-2-2V2a2,2,0,0,1,2-2h12a2,2,0,0,1,2,2v8a2,2,0,0,1-2,2H15.582l-2.251,3Z"
        transform="translate(-3.333)"
        fill="none"
        stroke="#2d323c"
        stroke-linejoin="round"
        stroke-width="1"
      />
      <line
        id="Line_329"
        data-name="Line 329"
        x2="7"
        transform="translate(3 8)"
        fill="none"
        stroke="#2d323c"
        stroke-width="1"
      />
      <line
        id="Line_337"
        data-name="Line 337"
        x2="10"
        transform="translate(3 4)"
        fill="none"
        stroke="#2d323c"
        stroke-width="1"
      />
      <path
        id="Path_1992"
        data-name="Path 1992"
        d="M0,0"
        transform="translate(8.731 3.134) rotate(90)"
        fill="none"
        stroke="#2d323c"
        stroke-width="1"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Notes",
};
</script>

<style lang="scss" scoped>
</style>